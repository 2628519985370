import React from "react";
import BathTub from "../../../Assets/images/bathtub.jpg";
import ScheduleImg from "../../../Assets/images/Commercial-IMG_7765.jpg"
import {Helmet} from "react-helmet";

const Schedule = () =>{
    return(
        <>
            <Helmet>
                <title>Schedule | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
            </Helmet>
            <div className="container-fluid g-0 position-relative">
                <div className="about-banner-overly"/>
                <img src={BathTub} className={'img-fluid about-banner'} alt={'About Us'}/>
                <div className="about-overly">
                    <h1 className={'text-center text-white'}>Schedule</h1>
                </div>
            </div>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-4">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search for event" aria-label="Username"/>
                            <span className="input-group-text" id="basic-addon1"><i className="fas fa-search"/></span>
                        </div>
                    </div>
                    <div className="col-md-8 text-end">
                        <button className={'btn btn-success me-5'}>Find Events</button>
                        <button className={'btn btn-info me-2'}>List</button>
                        <button className={'btn btn-info me-2'}>Month</button>
                        <button className={'btn btn-info'}>Day</button>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-12">
                        <h4>Latest Past Events</h4>
                    </div>
                    <div className="col-md-12 my-5">
                        <div className="row">
                            <div className="col-md-2">
                               <p>MAY</p>
                               <h6>9</h6>
                                <p>2021</p>
                            </div>
                            <div className="col-md-5 position-relative">
                                <div className={'schedule-separator'}/>
                                <p className={'schedule-feature'}><i className="far fa-clipboard text-success"/> Featured <span>May 9 @ 8:00 am - 10:00 am UTC+0</span>  </p>
                                <h4 className={'mt-4'}>Shower Pan</h4>
                                <p className={'mt-4'}>Truck - 2</p>
                            </div>
                            <div className="col-md-5">
                                <img src={ScheduleImg} className={'img-thumbnail'} alt={'Schedule'}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 my-5">
                        <div className="row">
                            <div className="col-md-2">
                                <p>MAY</p>
                                <h6>9</h6>
                                <p>2021</p>
                            </div>
                            <div className="col-md-5 position-relative">
                                <div className={'schedule-separator'}/>
                                <p className={'schedule-feature'}><i className="far fa-clipboard text-success"/> Featured <span>May 9 @ 8:00 am - 10:00 am UTC+0</span>  </p>
                                <h4 className={'mt-4'}>Shower Pan</h4>
                                <p className={'mt-4'}>Truck - 2</p>
                            </div>
                            <div className="col-md-5">
                                <img src={ScheduleImg} className={'img-thumbnail'} alt={'Schedule'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Schedule
