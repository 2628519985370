import React from "react";
import Img4 from '../../../Assets/images/photos/WAKINGSHOWER.jpg'
import Slider from "../Includes/Slider";
import {Helmet} from "react-helmet";

const About = () =>{
    return(
        <>
            <Helmet>
                <title>About Us | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
            </Helmet>
            <Slider/>
            <div className="container text-center mt-5">
                <div className="col-md-12">
                    <h1 className={'text-center text-center'}>About US</h1>
                </div>
            </div>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Mega Hot Mop</h2>
                        <p>Mega Hot Mop have been serving families and businesses throughout Los Angeles County & San Fernando Valley, CA. In fact, Our experts live in the neighborhoods where we provide service, Call us at (818) 770-6891, so you can count on us to take every opportunity to keep our communities clean and safe for all of our families.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={Img4} className={'img-fluid about-section-img'} alt={'About'}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default About
