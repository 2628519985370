import React, {useEffect, useState} from "react";
import Nav from "./Include/Nav";
import axios from "axios";
import BASEURL from "../../../Constants";
import {Link} from "react-router-dom";

const ClientAppointment = () => {

    useEffect(() => {
        getAppointments()
    }, []);

    const [appointment, setAppointment] = useState([])
    const getAppointments = () => {
        axios.get(BASEURL+'/api/client/get-appointments?payment_status=COMPLETED').then(res=>{
            setAppointment(res.data.data)
            console.log(res.data.data)
        })
    }

    return (
        <>
            <div className="container my-2 py-5 client-area-section-gap">
                <div className="row">
                    <div className="col-md-3">
                        <Nav/>
                    </div>
                    <div className="col-md-9">
                        <h4>Appointments</h4>
                        <hr/>
                        <table className={'table table-hover table-bordered'}>
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Service Type</th>
                                    <th>Size</th>
                                    <th>Date & Time</th>
                                    <th>Amount</th>
                                    <th>Payment Status</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {appointment.map((item, index)=>(
                                <tr key={index}>
                                    <td className={'align-middle'}>{index+1}</td>
                                    <td className={'align-middle'}>{item.service_type}</td>
                                    <td className={'align-middle'}>{item.size}</td>
                                    <td className={'align-middle'}>{item.date_time}</td>
                                    <td className={'align-middle'}>{item.amount != null && item.amount !=0 ?'$ '+item.amount:'Will Update'}</td>
                                    <td className={'align-middle'}>{item.payment_status}</td>
                                    <td className={'align-middle'}>{item.status==1?'Pending' : item.status}</td>
                                    <td className={'align-middle'}>
                                        <Link to={'/client/appointment-details/'+item.id}><button className={'btn btn-sm btn-info'}><i className={'fas fa-eye'}/></button></Link>
                                        <button className={'btn btn-sm btn-warning ms-1'}><i className={'fas fa-edit'}/></button>
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ClientAppointment