import React, {useEffect, useState} from "react";
import BathTub from "../../../../Assets/images/bathtub.jpg";
import axios from "axios";
import BASEURL from "../../../../Constants";
import {toast} from "react-toastify";
import {Link, useHistory} from "react-router-dom";
import Slider from "../../Includes/Slider";
import {Helmet} from "react-helmet";
const Register = () => {
    const history = useHistory()
    useEffect(()=>{
        if (localStorage.token != undefined){
            history.push('/client-area')
        }
    }, [])
    const [inputData, setInputData] = useState({})
    const handleInput = (e) =>{
        let name = e.target.name
        let value = e.target.value
        setInputData(prev=>({...prev, [name]: value}))
    }
    const [errMsg, setErrMsg] =useState({})
    const saveRegistration = (e) => {
        e.preventDefault()
        axios.post(BASEURL+'/api/client/registration' , inputData ).then(res=>{
            if(res.data.token != undefined){
                setErrMsg({})
                localStorage.name = res.data.user.name
                localStorage.email = res.data.user.email
                localStorage.token = res.data.token
                history.push('/client-area')
                toast.success('Registration Successful');
                window.location.reload()

            }
        }).catch(errors=>{
            if (errors.response.status == 422){
                setErrMsg(errors.response.data.errors)
            }
        })
    }
    return(
        <>
            <Helmet>
                <title>Registration | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
            </Helmet>
            <Slider/>
            <div className="container text-center mt-5">
                <div className="col-md-12">
                    <h1 className={'text-center text-center'}>Register</h1>
                </div>
            </div>
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>Register</h4>
                            </div>
                            <div className="card-body">
                                <form className={'px-4 py-2'}>
                                    <label className={'w-100'}>
                                        Name
                                        <input onChange={handleInput} type={'text'} name={'name'} className={'form-control mt-2'}/>
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <p  key={key}>
                                                <small
                                                    className="text-danger validation-error-message">
                                                    {key == 'name' ? val : ''}
                                                </small>
                                            </p>
                                        ))}
                                    </label>
                                    <label className={'w-100 mt-2'}>
                                        Email
                                        <input onChange={handleInput} type={'email'} name={'email'} className={'form-control mt-2'}/>
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <p  key={key}>
                                                <small
                                                    className="text-danger validation-error-message">
                                                    {key == 'email' ? val : ''}
                                                </small>
                                            </p>
                                        ))}
                                    </label>
                                    <label className={'w-100 mt-2'}>
                                        Phone
                                        <input onChange={handleInput} type={'number'} name={'phone'} className={'form-control mt-2'}/>
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <p  key={key}>
                                                <small
                                                    className="text-danger validation-error-message">
                                                    {key == 'phone' ? val : ''}
                                                </small>
                                            </p>
                                        ))}
                                    </label>
                                    <label className={'w-100 mt-2'}>
                                        Password
                                        <input onChange={handleInput} type={'password'} name={'password'} className={'form-control mt-2'}/>
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <p  key={key}>
                                                <small
                                                    className="text-danger validation-error-message">
                                                    {key == 'password' ? val : ''}
                                                </small>
                                            </p>
                                        ))}
                                    </label>
                                    <label className={'w-100 mt-2'}>
                                        Confirm Password
                                        <input onChange={handleInput} type={'password'} name={'password_confirmation'} className={'form-control mt-2'}/>
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <p  key={key}>
                                                <small
                                                    className="text-danger validation-error-message">
                                                    {key == 'password_confirmation' ? val : ''}
                                                </small>
                                            </p>
                                        ))}
                                    </label>
                                    <div className="row justify-content-center">
                                        <div className="col-6">
                                            <div className="d-grid">
                                                <button onClick={saveRegistration} className={'btn btn-outline-info mt-3'}>Register</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <p className={'text-center'}>Already Registered? Please <Link to={'/login'}>Login</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Register