import React from "react";
import Slider from "../Includes/Slider";
import {Helmet} from "react-helmet";

const Contact = () =>{
    return(
        <>
            <Helmet>
                <title>Contact Us | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
            </Helmet>
            <Slider/>
            <div className="container text-center mt-5">
                <div className="col-md-12">
                    <h1 className={'text-center text-center'}>Contact</h1>
                </div>
            </div>
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <h2 className={'my-4'}>Mega Hot Mop</h2>
                        <p className={'my-4'}>Contact us for the services with Mega Hot Mop & Shower Pan</p>
                        <table className={'table table-borderless table-hover custom-table'}>
                            <tbody>
                                <tr>
                                    <th colSpan={2}>Mega Hot Mop, Shower Pan</th>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>:8444 Reseda Blvd, Northridge, CA 91324, United States</td>
                                </tr>
                                <tr>
                                    <th>Phone</th>
                                    <td>: <a href={'callto:8187737571'}> (818) 773 – 7571</a> </td>
                                </tr>
                                <tr>
                                    <th>Cell</th>
                                    <td>: <a href={'callto:8187706891'}>(818) 770 – 6891</a> </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="col-lg-6">
                        <h2 className={'my-4'}>REQUEST SERVICE</h2>
                        <p className={'my-4'}>Enter your contact details here to help us serve you better & faster.</p>
                        <form className="email-form">
                            <div className="row">
                                <div className="col form-group mt-3">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required=""/>
                                </div>
                                <div className="col form-group mt-3">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required=""/>
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="phone" id="phone" placeholder="Phone" required=""/>
                            </div>
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required=""/>
                            </div>
                            <div className="form-group mt-3">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required=""/>
                            </div>
                            <p className={'my-3'}>Please upload your Hot Mop Shower Pan images:</p>
                            <div className="input-group mb-3">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile01"/>
                                </div>
                            </div>
                            <div className="text-center">
                                <button className={'submit-contact'} type="submit">GET QUOTE</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="container-fluid g-0">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe src="https://www.google.com/maps/d/embed?mid=1jeDH2NgJ-imvD1W3cINH3eWOgJArOye2&ehbc=2E312F"  frameBorder="0" allowFullScreen={true} aria-hidden="false" tabIndex="0" height="400"/>
            </div>
        </>
    )
}
export default Contact
