import React, {useEffect, useState} from 'react';
import {ToastContainer} from "react-toastify";
import Nav from "./Include/Nav";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import BASEURL from "../../../Constants";

const MyComponent = () => {
    const history = useHistory()
    useEffect(() => {
        getAppointments()
    }, []);

    const [appointment, setAppointment] = useState([])
    const getAppointments = () => {
        axios.get(BASEURL+'/api/client/get-appointments?payment_status='+null).then(res=>{
            setAppointment(res.data.data)
        })
    }

    const handlePaymentRedirect = (id, e) => {
        localStorage.appointment_id = id
        history.push('/pay-now')
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />

            <div className="container my-2 py-5 client-area-section-gap">
                <div className="row">
                    <div className="col-md-3">
                        <Nav/>
                    </div>
                    <div className="col-md-9">
                        <h4>Payment</h4>
                        <hr/>
                        <table className={'table table-hover table-bordered'}>
                            <thead>
                            <tr>
                                <th>SL</th>
                                <th>Service Type</th>
                                <th>Size</th>
                                <th>Date & Time</th>
                                <th>Amount</th>
                                <th>Payment Status</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {appointment.map((item, index)=>(
                                <tr key={index}>
                                    <td className={'align-middle'}>{index+1}</td>
                                    <td className={'align-middle'}>{item.service_type}</td>
                                    <td className={'align-middle'}>{item.size}</td>
                                    <td className={'align-middle'}>{item.date_time}</td>
                                    <td className={'align-middle'}>{item.amount != null && item.amount !=0 ?'$ '+item.amount:'Will Update'}</td>
                                    <td className={'align-middle'}>{item.payment_status}</td>
                                    <td className={'align-middle'}>{item.status==1?'Pending' : item.status}</td>
                                    <td className={'align-middle'}>
                                        <button onClick={(e)=>handlePaymentRedirect(item.id, e)} className={'btn btn-outline-info ms-1'}><i className="fab fa-paypal"/> Pay Now</button>
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyComponent;
