import React from "react";
import Nav from "./Include/Nav";
import GetEstimate from "./Include/GetEstimate";
import Slider from "../../Includes/Slider";
import {Helmet} from "react-helmet";

const Balconies = () => {
    return(
        <>
            <Helmet>
                <title>Hot Mop Balconies | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
            </Helmet>
            <Slider/>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-3">
                        <Nav/>
                    </div>
                    <div className="col-md-9">
                        <h4 className={'mb-4'}>Hot Mop Balconies</h4>
                        <p>Mega Hot Mop / Shower Pan, does warranted residential Hot Mop services around Los Angeles County & San Fernando Valley.</p>
                        <p className={'mt-4'}>All Season Mega Hot Mop Service, we are aware, reliable, and affordable Hot Mop | Shower Pan in South California.</p>
                        <p>We have made it our priority to provide the best customer service, affordable prices, and the most dependable Hot Mop | Shower Pan San Fernando Valley, Los Angeles County, Ventura County, Riverside County, Orange County, Ventura County.</p>

                    <GetEstimate/>
                    </div>
                </div>
            </div>
        </>
        )

}
export default Balconies