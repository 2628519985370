import React, {useEffect, useState} from 'react'
import Logo from '../../../Assets/images/Logo-1-1.png'
import {Link, NavLink, useHistory} from "react-router-dom";
import {Modal} from "react-bootstrap";
import axios from "axios";
import BASEURL from "../../../Constants";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {toast, ToastContainer} from "react-toastify";

const NavBar = (props) =>{

    useEffect(()=>{
        console.log(props)
    },[])

    const [social] = useState([
       {name : 'facebook', link : 'https://www.facebook.com/MegaBusinessUSAcom-294802020626441'},
       {name : 'instagram', link : 'https://www.instagram.com/megabusinessusa'},
       {name : 'twitter', link : 'https://twitter.com/MegaBusinessUSA'},
       // {name : 'whatsapp', link : '/'},
    ])
    const  [menu] = useState([
        {name: 'Home', link : '/'},
        {name: 'About Us', link : '/about-us'},
        {name: 'All Services', link : '/all-services'},
        {name: 'Payment', link : '/client/payment'},
        {name: 'Schedule', link : '/client/schedule'},
        {name: 'Contact us', link : '/contact-us'},
    ])
    const [isModalOpen, setModalStatus] = useState(false);
    const handleModal = (e) =>{
            setModalStatus(true)
    }
    const [modalTitle, setModalTitle] = useState('Login')
    const [inputData, setInputData] = useState({})
    const handleInput = (e) =>{
        let name = e.target.name
        let value = e.target.value
        setInputData(prev=>({...prev, [name]: value}))
    }
    const [errMsg, setErrMsg] =useState({})
    const [congrats, setCongrats] =useState(null)
    const [userMenu, setUsermenu] = useState('none')

    const handleUserMenu = () => {
        userMenu == 'none'?setUsermenu('block'):setUsermenu('none')
    }

    const saveRegistration = (e) => {
        e.preventDefault()
        axios.post(BASEURL+'/api/client/registration' , inputData ).then(res=>{
            if(res.data.token != undefined){
                setCongrats('Congratulation '+res.data.user.name + ' Please Login')
                setErrMsg({})
                setModalStatus(false)
                localStorage.name = res.data.user.name
                localStorage.email = res.data.user.email
                localStorage.token = res.data.token
                setModalStatus(false)
                history.push('/client-area')
                toast.success('Registration Successful');
                window.location.reload()

            }
        }).catch(errors=>{
            if (errors.response.status == 422){
                setErrMsg(errors.response.data.errors)
            }
        })
    }
    const history = useHistory();
    const logout = () => {
        let token = localStorage.token
        if (token){
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        axios.post(BASEURL+'/api/client/logout').then(res=>{
            localStorage.removeItem('token')
            localStorage.removeItem('name')
            localStorage.removeItem('email')
            setUsermenu('none')
            history.push('/')
            window.location.reload()
        })
    }

    const login = (e) =>{
        e.preventDefault()
        axios.post(BASEURL+'/api/client/login', inputData).then(res=>{
            localStorage.name = res.data.name
            localStorage.email = res.data.email
            localStorage.token = res.data.token
            setModalStatus(false)
            history.push('/client-area')
            window.location.reload()
        }).catch(errors=>{
            if(errors.response.status == 422){
                setErrMsg(errors.response.data.errors)
        }
        })
    }

    return(
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <section className="front-header-section">
                <div className="front-top-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 ">
                                <ul className="front-info-inner text-md-start text-center">
                                    <li>
                                        <a href="tel:8187706891">
                                            <i className="fas fa-mobile-alt"/> (818) 770 – 6891
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:megahotmop@gmail.com">
                                            <i className="far fa-envelope-open"/> megahotmop@gmail.com
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-md-5">
                                <p className="text-center"> We are Serving for Hot Mop Shower Pan Entire California Area, USA</p>
                            </div>
                            <div className="col-md-3">
                                <ul className="social-icons text-md-end text-center">
                                    <li>
                                        {social.map((item, index)=>(
                                            <a href={item.link} key={index} target={'_blank'} rel={"referrer noreferrer"}>
                                                <i className={"fab fa-"+item.name}/>
                                            </a>
                                        ))}
                                    </li>
                                    <li>
                                        {localStorage.token != undefined?
                                            <div className={'front-end-user-menu-container'}>
                                                <button onClick={handleUserMenu} className={'user_button'}>{localStorage.name} <i className="far fa-user-circle text-success"/></button>
                                                <div className={'front-end-user-menu'} style={{display:userMenu}}>
                                                    <ul>
                                                        <li><Link onClick={handleUserMenu} to={'/client/profile'}>Profile</Link></li>
                                                        <li><Link onClick={handleUserMenu} to={'/client/change-password'}>Change Password</Link></li>
                                                        <li><Link onClick={handleUserMenu} to={'/client/appointment'}>Appointments</Link></li>
                                                        <li><Link onClick={handleUserMenu} to={'/client/schedule'}>Schedule</Link></li>
                                                        <li><Link onClick={handleUserMenu} to={'/client/payment'}>Payment</Link></li>
                                                        <li><button onClick={logout} className={'logout-button'}>Logout</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            :
                                            <button onClick={(e) => handleModal(e)} className={'btn user-button btn-sm btn-outline-light ms-3'}><i className={'fas fa-user'}/></button>
                                        }


                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} className="logo-image" alt="logo"/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto">
                                {menu.map((menu, index)=>(
                                    <li className="nav-item" key={index}>
                                        <NavLink className="nav-link" to={menu.link}>{menu.name}</NavLink>
                                    </li>
                                ))}
                            </ul>
                            <NavLink to={'/get-appointment'}><button className="btn get" type="button"> Get Appointment</button></NavLink>
                        </div>
                    </div>
                </nav>

                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={isModalOpen}
                    onHide={() => setModalStatus(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span>{modalTitle}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="login-registration-panel">
                            <Tabs>
                                <TabList>
                                    <Tab onClick={()=>setModalTitle('Login')}>Login</Tab>
                                    <Tab onClick={()=>setModalTitle('Register')}>Register</Tab>
                                </TabList>
                                <TabPanel>
                                    <form className={'px-4 py-2'}>
                                        <label className={'w-100'}>
                                            Email/Phone
                                            <input onChange={handleInput} type={'text'} name={'email'} className={'form-control mt-2'}/>
                                            {Object.entries(errMsg).map(([key, val]) => (
                                                <p  key={key}>
                                                    <small
                                                        className="text-danger validation-error-message">
                                                        {key == 'email' ? val : ''}
                                                    </small>
                                                </p>
                                            ))}
                                        </label>
                                        <label className={'w-100 mt-2'}>
                                            Password
                                            <input onChange={handleInput} type={'password'} name={'password'} className={'form-control mt-2'}/>
                                            {Object.entries(errMsg).map(([key, val]) => (
                                                <p  key={key}>
                                                    <small
                                                        className="text-danger validation-error-message">
                                                        {key == 'password' ? val : ''}
                                                    </small>
                                                </p>
                                            ))}
                                        </label>
                                        <div className="row justify-content-center">
                                            <div className="col-6">
                                                <div className="d-grid">
                                                    <button onClick={login} className={'btn btn-outline-info mt-3'}>Login</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </TabPanel>
                                <TabPanel>
                                    <small className={'text-center text-success'}>{congrats}</small>
                                    <form className={'px-4 py-2'}>
                                        <label className={'w-100'}>
                                            Name
                                            <input onChange={handleInput} type={'text'} name={'name'} className={'form-control mt-2'}/>
                                            {Object.entries(errMsg).map(([key, val]) => (
                                                <p  key={key}>
                                                    <small
                                                        className="text-danger validation-error-message">
                                                        {key == 'name' ? val : ''}
                                                    </small>
                                                </p>
                                            ))}
                                        </label>
                                        <label className={'w-100 mt-2'}>
                                            Email
                                            <input onChange={handleInput} type={'email'} name={'email'} className={'form-control mt-2'}/>
                                            {Object.entries(errMsg).map(([key, val]) => (
                                                <p  key={key}>
                                                    <small
                                                        className="text-danger validation-error-message">
                                                        {key == 'email' ? val : ''}
                                                    </small>
                                                </p>
                                            ))}
                                        </label>
                                        <label className={'w-100 mt-2'}>
                                            Phone
                                            <input onChange={handleInput} type={'number'} name={'phone'} className={'form-control mt-2'}/>
                                            {Object.entries(errMsg).map(([key, val]) => (
                                                <p  key={key}>
                                                    <small
                                                        className="text-danger validation-error-message">
                                                        {key == 'phone' ? val : ''}
                                                    </small>
                                                </p>
                                            ))}
                                        </label>
                                        <label className={'w-100 mt-2'}>
                                            Password
                                            <input onChange={handleInput} type={'password'} name={'password'} className={'form-control mt-2'}/>
                                            {Object.entries(errMsg).map(([key, val]) => (
                                                <p  key={key}>
                                                    <small
                                                        className="text-danger validation-error-message">
                                                        {key == 'password' ? val : ''}
                                                    </small>
                                                </p>
                                            ))}
                                        </label>
                                        <label className={'w-100 mt-2'}>
                                            Confirm Password
                                            <input onChange={handleInput} type={'password'} name={'password_confirmation'} className={'form-control mt-2'}/>
                                            {Object.entries(errMsg).map(([key, val]) => (
                                                <p  key={key}>
                                                    <small
                                                        className="text-danger validation-error-message">
                                                        {key == 'password_confirmation' ? val : ''}
                                                    </small>
                                                </p>
                                            ))}
                                        </label>
                                        <div className="row justify-content-center">
                                            <div className="col-6">
                                                <div className="d-grid">
                                                    <button onClick={saveRegistration} className={'btn btn-outline-info mt-3'}>Register</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default NavBar
