import React, {useState} from "react";
import Nav from "./Include/Nav";
import axios from "axios";
import BASEURL from "../../../Constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
    const [inputData, setInputData] = useState({})
    const handleInput = (e) =>{
        let name = e.target.name
        let value = e.target.value
        setInputData(prev=>({...prev, [name]: value}))
    }
    const [errMsg, setErrMsg] = useState({})
    const changePassword = (e) => {
        e.preventDefault()
      axios.post(BASEURL+'/api/client/reset-password',inputData).then(res=>{
          setInputData({})
          setErrMsg({})
          toast.success(res.data.msg);
      }).catch(errors=>{
          if (errors.response.status == 422){
              setErrMsg(errors.response.data.errors)
          }
      })
    }
  return(
      <>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />

          <div className="container my-2 py-5 client-area-section-gap">
              <div className="row">
                  <div className="col-md-3">
                      <Nav/>
                  </div>
                  <div className="col-md-9">
                      <h4>Change Password</h4>
                      <hr/>
                      <div className="row justify-content-center">
                          <div className="col-md-6">
                              <div className="card">
                                  <div className="card-body">
                                      <label>Enter Password</label>
                                      <input onChange={handleInput} type={'password'} defaultValue={inputData.passowrd} name={'password'} className={'form-control'}/>
                                      {Object.entries(errMsg).map(([key, val]) => (
                                          <p  key={key}>
                                              <small
                                                  className="text-danger validation-error-message">
                                                  {key == 'password' ? val : ''}
                                              </small>
                                          </p>
                                      ))}
                                      <label className={'mt-4'}>Confirm Password</label>
                                      <input  onChange={handleInput} defaultValue={inputData.password_confirmation} type={'password'} name={'password_confirmation'} className={'form-control'}/>
                                      {Object.entries(errMsg).map(([key, val]) => (
                                          <p  key={key}>
                                              <small
                                                  className="text-danger validation-error-message">
                                                  {key == 'password_confirmation' ? val : ''}
                                              </small>
                                          </p>
                                      ))}
                                      <div className="d-grid">
                                          <button onClick={changePassword} className={'btn btn-success mt-4'}>Change Password</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}
export default ChangePassword