import React from "react";
import BathTub from "../../../../Assets/images/bathtub.jpg";
import Nav from "./Include/Nav";
import Img1 from "../../../../Assets/images/service/Hot-Mop-Decks.jpg";
import GetEstimate from "./Include/GetEstimate";
import Slider from "../../Includes/Slider";
import {Helmet} from "react-helmet";

const Decks = () => {
  return(
      <>
        <Helmet>
          <title>Hot Mop Decks | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
        </Helmet>
        <Slider/>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-3">
              <Nav/>
            </div>
            <div className="col-md-9">
              <h4 className={'mb-4'}>Hot Mop Decks</h4>
              <p>Long on life span and short on fuss, composite decking is an excellent choice when building the perfect outdoor living space for your family to enjoy. These man-made boards will never splinter, rot and will never need staining. They are built to withstand the most brutal weather conditions. The team at Coastal Home Remodeling has the experience and know-how to build the deck of your dreams.</p>
              <div className="row">
                <div className="col-md-4 mt-5">
                  <img src={Img1} alt={'Hot Mop Shower Pan'} className={'img-thumbnail'}/>
                </div>
                <div className="col-md-8 mt-5">
                  <h4>Key Benefit of Service </h4>
                  <p className={'my-4'}>Whether you require shower pan service, call us. we take pride in
                    the work we perform with our dedicated plumbers.</p>
                  <div className="row">
                    <div className="col-6">
                      <p><i className="fas fa-check"/> Locally Own Operated</p>
                      <p><i className="fas fa-check"/> Bonded and insured team</p>
                      <p><i className="fas fa-check"/> Upfront, Flat Rate Pricing</p>
                    </div>
                    <div className="col-6">
                      <p><i className="fas fa-check"/> Workmanship guaranteed</p>
                      <p><i className="fas fa-check"/> High skilled experts</p>
                      <p><i className="fas fa-check"/> Friendly, professionals</p>
                    </div>
                  </div>
                </div>
              </div>
              <GetEstimate/>
            </div>
          </div>
        </div>
      </>
  )
}
export default Decks