import React from "react";
import {NavLink} from "react-router-dom";

const Nav = () => {
    return (
        <div className="service-nav">
            <NavLink activeClassName={'service-nav-item-active'} to={'/all-services'}>All
                Services</NavLink>
            <NavLink activeClassName={'service-nav-item-active'} to={'/services/hot-mop-shower-pan'}>Hot
                Mop Shower Pan</NavLink>
            <NavLink activeClassName={'service-nav-item-active'} to={'/services/hot-mop-balconies'}>HOT
                MOP BALCONIES</NavLink>
            <NavLink activeClassName={'service-nav-item-active'} to={'/services/hot-mop-stair'}>HOT MOP
                STAIR</NavLink>
            <NavLink activeClassName={'service-nav-item-active'} to={'/services/hot-mop-planter-boxes'}>HOT
                MOP PLANTER BOXES</NavLink>
            <NavLink activeClassName={'service-nav-item-active'} to={'/services/hot-mop-decks'}>HOT MOP
                DECKS</NavLink>
            <NavLink activeClassName={'service-nav-item-active'} to={'/services/water-proofing'}>HOT MOP
                WATERPROOFING</NavLink>
            <NavLink activeClassName={'service-nav-item-active'} to={'/services/hot-mop-residential'}>HOT
                MOP RESIDENTIAL</NavLink>
            <NavLink activeClassName={'service-nav-item-active'} to={'/services/hot-mop-commercial'}>HOT
                MOP COMMERCIAL</NavLink>
        </div>
    )
}
export default Nav