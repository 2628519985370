import React from "react"
import BathTub from "../../../Assets/images/bathtub.jpg";
import {Helmet} from "react-helmet";

const PrivacyPolicy = () => {
  return(
      <>
          <Helmet>
              <title>Privacy Policy | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
          </Helmet>
          <div className="container-fluid g-0 position-relative">
              <div className="about-banner-overly"/>
              <img src={BathTub} className={'img-fluid about-banner'} alt={'About Us'}/>
              <div className="about-overly">
                  <h1 className={'text-center text-white'}>Privacy Policy</h1>
              </div>
          </div>
          <div className="container my-5">
              <div className="row">
                  <div className="col-md-12">
                      <h6 className={'text-uppercase'}>Sublime Productions Inc/Mega hot mop Inc/Mega Hot mop/MegabusinessUSA.com/ Sublimelist.com Privacy (Last updated January 2, 2015)</h6>
                      <h5 className={'my-4'}>Terms of Use</h5>
                      <p>This policy details how data about you is collected/used/disclosed when you visit our websites and services or otherwise interact with us. If we update it, we will revise the date, place notices on Megahotmop.com if a change is material, and/or obtain your consent as required by law.</p>
                      <p className={'my-2'}><strong>1. Protecting your privacy:</strong></p>
                      <ul className={'privacy-list'}>
                          <li>We take precautions to prevent unauthorized access to or misuse of data about you.</li>
                          <li>We do not run ads, other than classified ads posted by our users.</li>
                          <li>We do not share your data with third parties for marketing purposes.</li>
                          <li>We do not engage in cross-marketing or link-referral programs with other sites.</li>
                          <li>We do not employ tracking devices for marketing purposes.</li>
                          <li>We do not send you unsolicited communications for marketing purposes.</li>
                          <li>We do not engage in affiliate marketing (and prohibit it on Megahotmop.com).</li>
                          <li>We do provide email proxy & relay to reduce unwanted mail.</li>
                          <li>Megahotmop.com links to third party sites – please review their privacy policies.</li>
                      </ul>
                      <p className={'my-2'}><strong>2. Data we use to provide/improve our services and/or combat fraud/abuse:</strong></p>
                      <ul className={'privacy-list'}>
                          <li>data you post on Megahotmop.com or send us directly or via other sites. Please do not post private data.</li>
                          <li>credit card data, which is transmitted to payment processors via a security protocol (e.g. SSL).</li>
                          <li>data you provide (e.g. email address, name, address, phone, fax, photos, tax ID).</li>
                          <li>web log data (e.g. pages viewed, access times, IP address, HTTP headers). data collected via cookies (e.g. keyword searches and “favorites” lists). data about your devices (e.g. screen size, DOM local storage, plugins). data from 3rd parties (e.g. phone type, general location derived from IP address).</li>
                      </ul>
                      <p className={'my-2'}><strong>3. Data we store:</strong></p>
                      <ul className={'privacy-list'}>
                          <li>We retain data as long as needed for our business purposes and/or as required by law.</li>
                          <li>We make good faith efforts to store data securely, but make no guarantees. You may access and update certain data about you by logging into your account.</li>
                      </ul>
                      <p className={'my-2'}><strong>4. Circumstances in which we may disclose user data:</strong></p>
                      <ul className={'privacy-list'}>
                          <li>to vendors and service providers (e.g. payment processors) working on our behalf.</li>
                          <li>to respond to subpoenas, court orders, or other legal process.</li>
                          <li>to protect our rights, property, or safety, or that of our users or the general public.</li>
                          <li>with your consent (e.g. if you authorize us to share data with other users).</li>
                          <li>in connection with a merger, bankruptcy, or sale/transfer of assets to another company.</li>
                          <li>in aggregate or summary form, as long as it cannot reasonably be used to identify you.</li>
                      </ul>
                      <p className={'mt-2'}>International Users – By visiting Megahotmop.com or providing us data, you agree we may use and disclose data we collect for purposes listed here or as communicated to you, transmit it outside your resident jurisdiction, and store it on servers in the United States. For more information please contact us:
                      <a href={'mailto:sublimeproinc@gmail.com'}> sublimeproinc@gmail.com</a>
                      </p>
                  </div>
              </div>
          </div>
      </>

  )
}
export default PrivacyPolicy