import React, {useEffect, useState} from "react";
import BathTub from "../../Assets/images/bathtub.jpg";
import { PayPalButton } from "react-paypal-button-v2";
import axios from "axios";
import BASEURL from "../../Constants";
import {useHistory} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

const PayNow = () => {
    const history = useHistory()
    useEffect(()=>{
        getPrice()
    },[])

    const [order, setOrder] =useState([])
    const getPrice = () => {
        axios.get(BASEURL+'/api/client/get-order-price/'+localStorage.appointment_id).then(res=>{
            setOrder(res.data)
            if (res.data.price == null || res.data.price == 0){
                history.push('/client/appointment')
            }
        })
    }

    const paymentSave = (details, data) => {
        let order = {
            order_id: data.orderID,
            payer_id: data.payerID,
            transaction_id: details.id,
            status: details.status,
            create_time: details.create_time,
            amount: details.purchase_units[0].amount.value,
            appointment_id: localStorage.appointment_id
        }
        axios.post(BASEURL+'/api/client/save-transaction',  order).then(res=>{
            if (res.data.msg== "success"){
                toast.success('Payment Successful')
                localStorage.removeItem('appointment_id')
                history.push('/client/appointment')
            }
        })
    }

  return(
      <>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
          />
          <div className="container-fluid g-0 position-relative">
              <div className="about-banner-overly"/>
              <img src={BathTub} className={'img-fluid about-banner'} alt={'About Us'}/>
              <div className="about-overly">
                  <h1 className={'text-center text-white'}>Pay Now</h1>
              </div>
          </div>
          <div className="container my-5">
              <div className="row justify-content-center">
                  <div className="col-md-4">
                      <h6 className={'text-center mb-2'}>Payable Amount is : <span className={'text-success'}>${order.price}</span></h6>
                      <PayPalButton
                          amount={order.price}
                         // amount={1}
                          // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                          onSuccess={(details, data) => {
                              paymentSave(details, data)
                          }}
                          options={{
                              clientId: "AROozYrCScgMzNdkkB_6gOOW-2npea8IoGB9_LmfOZDv9rh-5WX7yTsX0FGkkD0NLFEGB77rVcbgQIlI"
                          }}
                      />
                  </div>
              </div>
          </div>
      </>
  )
}
export default PayNow