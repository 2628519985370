import React, {useEffect, useState} from "react";
import BathTub from "../../../Assets/images/bathtub.jpg";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from "axios";
import BASEURL from "../../../Constants";
import {Modal} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

const Payment = ()=>{

    const history = useHistory()
    useEffect(()=>{
        getType()
        getDistance()
        getSize()
    },[])

    const [inputData, setInputData] = useState({})
    const [typeOption, setTypeOption] = useState([])
    const renderTypeList =()=> {
        return (typeOption.map(data =>({label:data.type,value:data.id})))
    }
    const getType = () => {
      axios.get(BASEURL+'/api/client/get-type').then(res=>{
          setTypeOption(res.data)
      })
    }
    const [distanceOption, setDistanceOption] = useState([])
    const getDistance = () =>{
        axios.get(BASEURL+'/api/client/get-distance').then(res=>{
            setDistanceOption(res.data)
            console.log(res.data)
        })
    }
    const renderDistanceList =()=> {
        return (distanceOption.map(data =>({label:data.distance,value:data.id})))
    }

    const [ratingOption, setRatingOption] = useState([
        { value: '5', label: 'Perfect' },
        { value: '4', label: 'Good' },
        { value: '3', label: 'Average' },
        { value: '2', label: 'Not that bad' },
        { value: '1', label: 'Very Poor' },
    ])
    const [sizeOption, setSizeOption] = useState([])
    const getSize = () =>{
        axios.get(BASEURL+'/api/client/get-size').then(res=>{
            setSizeOption(res.data)
        })
    }
    const renderSizeList =()=> {
        return (sizeOption.map(data =>({label:data.size,value:data.id})))
    }
    const [sizeOther, setSizeOther] = useState(false)
    const [distanceOther, setdistanceOther] = useState(false)
    const handleSelect = (selectedOption, e) =>{
        if (selectedOption.value == 9 && e.name == 'size'){
            setSizeOther(true)
        }else if(selectedOption.value != 9 && e.name == 'size'){
            setSizeOther(false)
        }
        // if (selectedOption.value==4 &&  e.name == 'distance'){
        //     setdistanceOther(true)
        // }else {
        //     setdistanceOther(false)
        // }
        setInputData(prev => ({...prev, [e.name]: selectedOption.value}))
        console.log(inputData)
    }
    const handleInput = (e) =>{
        let name = e.target.name
        let value = e.target.value
        setInputData(prev=>({...prev, [name]: value}))
    }

    const [errMsg, setErrMsg] =useState({})
    const [previewData, setPreviewData] = useState([])
    const [isPreview, setIsPreview] = useState(0)

    const handlePreview = () => {
        setInputData(prev=>({...prev, appointment_id: localStorage.appointment_id}))
        setInputData(prev=>({...prev, preview: 'preview'}))
        setIsPreview(isPreview=>isPreview+1)
    }
    const handleSave = () => {
        setInputData(prev=>({...prev, appointment_id: localStorage.appointment_id}))
        setInputData(prev=>({...prev, preview: 'save'}))
        setIsPreview(isPreview=>isPreview+1)
    }
    useEffect(()=>{
        if(isPreview !== 0){
            getCalculatedPrice()
        }
    },[isPreview])

    const getCalculatedPrice = () => {

        axios.post(BASEURL+'/api/client/get-calculated-price', inputData).then(res=>{
            if (inputData.preview == 'preview'){
                setPreviewData(res.data)
                setModalStatus(true)
            }else{
                if (res.data.msg == 'success'){
                    setModalStatus(false)
                    //localStorage.removeItem('appointment_id')
                    toast.success('Your Appointment Saved Successfully');
                    history.push('/pay-now')
                }
            }
            setErrMsg({})
        }).catch(errors=>{
            if (errors.response.status == 422){
                setErrMsg(errors.response.data.errors)
            }
        })
    }

    const [isModalOpen, setModalStatus] = useState(false);


    return(
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="container-fluid g-0 position-relative">
                <div className="about-banner-overly"/>
                <img src={BathTub} className={'img-fluid about-banner'} alt={'About Us'}/>
                <div className="about-overly">
                    <h1 className={'text-center text-white'}>Payment</h1>
                </div>
            </div>
            <div className="container section-gap">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <h4>HOT MOP SHOWER PAN</h4>
                        <p className={'text-center mt-3'}>Price: $250.00 – $550.00</p>
                        <hr/>
                        <p className={'text-center'}>Our well experienced experts perform all Hot Mop services including:</p>
                        <div className="row mt-3">
                            <div className="col-6">
                                <p><i className="fas fa-check-double text-danger"/> Shower Pan </p>
                                <p><i className="fas fa-check-double text-danger"/> Drains </p>
                                <p><i className="fas fa-check-double text-danger"/> Decks </p>
                            </div>
                            <div className={'col-6'}>
                                <p className={'text-end'}>Water Proofing <i className="fas fa-check-double text-danger"/></p>
                                <p className={'text-end'}>Stair <i className="fas fa-check-double text-danger"/></p>
                                <p className={'text-end'}>Planter Boxes <i className="fas fa-check-double text-danger"/></p>
                            </div>
                        </div>
                        <hr/>
                        <div className="row mt-5 justify-content-center">
                            <div className="col-md-6">
                                <label>Select Type</label>
                                <Select
                                    options={renderTypeList()}
                                    placeholder = {'Select Option'}
                                    onChange ={handleSelect}
                                    name={'type'}
                                />
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p  key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'type' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                                <div className={'mt-3'}/>
                                <label>Select Distance</label>
                                <Select
                                    options={renderDistanceList()}
                                    placeholder = {'Select Option'}
                                    onChange ={handleSelect}
                                    name={'distance'}
                                />
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p  key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'distance' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                                {/*{distanceOther == true?<div>*/}
                                {/*    <div className={'mt-3'}/>*/}
                                {/*    <label>Enter Distance</label>*/}
                                {/*    <input onChange={handleInput} name={'custom_distance'} className={'form-control'} type={'text'} placeholder={'Enter Distance'}/>*/}
                                {/*</div>:null}*/}
                            </div>

                            <div className="col-md-6">
                                <label>Select Size</label>
                                <Select
                                    options={renderSizeList()}
                                    placeholder = {'Select Option'}
                                    onChange ={handleSelect}
                                    name={'size'}
                                />
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p  key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'size' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                                {sizeOther == true?<div>
                                    <div className={'mt-3'}/>
                                    <label>Custom Size</label>
                                    <input onChange={handleInput} name={'custom_size'} className={'form-control'} type={'text'} placeholder={'Enter Custom Size'}/>
                                </div>:null}
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p  key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'custom_size' ? val : ''}
                                        </small>
                                    </p>
                                ))}

                                <div className={'mt-3'}/>
                                <label>Enter Quantity</label>
                                <input onChange={handleInput} name={'quantity'} className={'form-control'} type={'number'} placeholder={'Quantity'}/>
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p  key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'quantity' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-3">
                                    <div className="d-grid">
                                        <button onClick={handlePreview} className={'btn get mt-5'}>Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-12">
                        <Tabs>
                            <TabList>
                                <Tab>DESCRIPTION</Tab>
                                <Tab>ADDITIONAL INFORMATION</Tab>
                                <Tab>REVIEWS (0)</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className={'my-5'}>Our well experienced experts perform all Hot Mop services including:</h4>
                                    </div>
                                    <div className="col-md-4">
                                        <p><i className="fas fa-check-double text-danger"/> Shower Pan</p>
                                        <p><i className="fas fa-check-double text-danger"/> Stair</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><i className="fas fa-check-double text-danger"/> Water Proofing</p>
                                        <p><i className="fas fa-check-double text-danger"/> Decks</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><i className="fas fa-check-double text-danger"/> Drains </p>
                                        <p><i className="fas fa-check-double text-danger"/> Planter Boxes</p>
                                    </div>

                                    <div className="col-md-12">
                                        <h4 className={'my-5'}>In Addition to our commitment towards excellence our advantages are :</h4>
                                        <p>
                                            <i className="fas fa-check-double text-danger"/> Free Estimates
                                            <i className="fas fa-check-double text-danger"/> Working since 1990
                                            <i className="fas fa-check-double text-danger"/> Total Quality Services and Works
                                            <i className="fas fa-check-double text-danger"/> 100 % Pass Inspection
                                            <i className="fas fa-check-double text-danger"/> 3 Years Warranty
                                        </p>
                                    </div>
                                </div>


                            </TabPanel>
                            <TabPanel>
                                <div className="row justify-content-center mt-5">
                                    <div className="col-md-6">
                                        <h4 className={'text-center'}>Additional Information</h4>
                                        <table className={'table table-hover table-stripped mt-5'}>
                                            <tbody>
                                                <tr>
                                                    <th>Bench</th>
                                                    <td>Bench, Without Bench</td>
                                                </tr>
                                                <tr>
                                                    <th>Size</th>
                                                    <td>3×3(LxW), 5×3(LxW), 4×4(LxW), 6×3(LxW), 6×4(LxW), 5×4(LxW), 5×5(LxW), 6×5(LxW)</td>
                                                </tr>
                                                <tr>
                                                    <th>Distance</th>
                                                    <td>1-20 miles, 21-35 miles, 36-70 miles</td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="row justify-content-center mt-5">
                                    <div className="col-md-8">
                                        <h4>Reviews</h4>
                                        <p className={'mt-3'}>There are no reviews yet.</p>
                                        <h4 className={'mt-3'}>Be the first to review “Hot Mop Shower Pan”</h4>
                                        <div className="row my-5">
                                            <div className="col-12">
                                                <p>Your email address will not be published. Required fields are marked *</p>
                                            </div>
                                            <div className="col-md-12">
                                                <input type={'text'} className={'form-control mt-3'} placeholder={'Name'}/>
                                            </div>
                                            <div className="col-md-6">
                                                <input type={'email'} className={'form-control mt-3'} placeholder={'Email'}/>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mt-3"/>
                                                <Select
                                                    options={ratingOption}
                                                    placeholder = {'Select your rating'}
                                                    onChange ={handleSelect}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <textarea className={'form-control mt-3'} placeholder={'Your Review '}/>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <input type={'checkbox'}/> Notify me of new posts by email.
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="d-grid">
                                                    <button className={'btn get'}>SUBMIT</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>


            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isModalOpen}
                onHide={() => setModalStatus(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span>Preview Your Appointment</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Contact Details</h6>
                   <table className={'table table-hover table-bordered'}>
                       <tbody>
                           <tr>
                               <th>Name</th>
                               <td>{previewData.name}</td>
                               <th>Email</th>
                               <td>{previewData.email}</td>
                           </tr>
                           <tr>
                               <th>Phone</th>
                               <td>{previewData.phone}</td>
                               <th>Cell</th>
                               <td>{previewData.cell}</td>
                           </tr>
                           <tr>
                               <th>Company</th>
                               <td>{previewData.company}</td>
                               <th>State</th>
                               <td>{previewData.state}</td>
                           </tr>
                           <tr>
                               <th>Address</th>
                               <td colSpan={3}>{previewData.address}</td>
                           </tr>
                       <tr>
                           <th>City</th>
                           <td>{previewData.city}</td>
                           <th>Zip</th>
                           <td>{previewData.zip}</td>
                       </tr>
                       </tbody>
                   </table>
                   <h6>Service Details</h6>
                    <table className={'table table-hover table-bordered'}>
                        <tbody>
                            <tr>
                                <th>Type</th>
                                <td>{previewData.type}</td>
                                <th>Size</th>
                                <td>{previewData.size}</td>
                            </tr>
                            <tr>
                                <th>Distance</th>
                                <td>{previewData.distance}</td>
                                <th>Quantity</th>
                                <td>{previewData.quantity}</td>
                            </tr>
                            <tr>
                                <th>Appointment Time</th>
                                <td colSpan={3}>{previewData.date_time}</td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td colSpan={3}>{previewData.description}</td>
                            </tr>
                            <tr>
                                <th>Service For</th>
                                <td>{previewData.service_for}</td>
                                <th>Service Type</th>
                                <td>{previewData.service_type}</td>
                            </tr>
                            <tr>
                                <th>Price</th>
                                <td colSpan={3}>{previewData.price != null && previewData.price != 0 ?'$ ' +previewData.price:'Admin Will Update Price'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="row justify-content-center">
                        <div className="col-6">
                            <div className="d-grid">
                                <button onClick={handleSave} className={'btn btn-outline-success'}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Payment
