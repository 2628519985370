import React, {useState} from "react";

import Img1 from '../../../Assets/images/use/Drain-Repair-.jpg'
import Img2 from '../../../Assets/images/use/Deep-Drain-.jpg'
import Img3 from '../../../Assets/images/use/Pipe-Hole-.jpg'
import Img4 from '../../../Assets/images/use/Before-Hot-Mop-.jpg'
import Img5 from '../../../Assets/images/use/Decor-for-Room-.jpg'
import Img6 from '../../../Assets/images/hot-mop/CUSTOME-SHOWER-HOT-MOP.jpg'

const Projects = () =>{
    const [images, setImages] = useState([
        {img : Img1, text:'DRAIN REPAIR'},
        {img : Img2, text: 'DEEP DRAIN'},
        {img : Img3, text: 'PIPE HOLE'},
        {img : Img4, text: 'BEFORE HOT MOP'},
        {img : Img5, text: 'DECOR FOR ROOM'},
        {img : Img6, text: 'SPECIAL BUILDING'},
    ])
    return(
        <section className="Projects-Drains ">
            <div className="container">
                <h1>Hot Mop Projects Drains We use it</h1>
                <p className="text-center Projects-Drains-p">Mega Hot Mop does Hot Mop & Shower Pan services around Los
                    Angeles County & San Fernando Valley.</p>
                <div className="row mb-4">
                    {images.map((img, index)=>(
                        <div className="col-md-4 col-sm-6 mb-4" key={index}>
                            <div className="box">
                                <div className="box-img">
                                    <img src={img.img} className="img-thumbnail project-image" alt=""/>
                                </div>
                                <div className="content text-center">
                                    <h3 className="title">{img.text}</h3>
                                    {/*<a href="/" className="read-more">View Projects</a>*/}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default Projects
