import React from "react";

import Img1 from '../../../Assets/images/hot-mop/RESIDENTIAL-HOT-MOP.jpg'
import Img2 from '../../../Assets/images/hot-mop/HOT-MOP-SHOWER-PAN.jpg'
import BathTub from '../../../Assets/images/photos/WAKINGSHOWER.jpg'
import {Link} from "react-router-dom";

const Expert = () =>{
    return(
        <section className="expert">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center">
                        <h2>We Are Expert on Hot Mop</h2>
                        <h5 className="my-2 text-theme-light">We are standard for Shower Pan</h5>
                        <p>Mega Hot Mop has been serving families and businesses throughout Los Angeles County & San Fernando Valley, CA. In fact, Our experts live in the neighborhoods where we provide service.</p>
                        <img src={BathTub} className="img-fluid img-thumbnail pic mb-md-0 mb-2" alt=""/>
                    </div>
                    <div className="col-md-3">
                        <div className="card mb-md-0 mb-2">
                            <img src={Img2} className="home-image card-img-top img border border-2" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title text-h5-title my-3">Commercial Hot Mop</h5>
                                    <p className="card-text mb-3">Mega Hot Mop / Shower Pan, does warranted Commercial
                                        Hot Mop services around Los Angeles County & San Fernando Valley.</p>
                                    <Link to="/all-services" className="">See Services <i className="fas fa-angle-double-right"/></Link>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <img src={Img1} className="home-image card-img-top img border border-2" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title text-h5-title my-3">HOT MOP RESIDENTIAL</h5>
                                    <p className="card-text mb-3">Mega Hot Mop / Shower Pan, does warranted residential Hot Mop services around Los Angeles County & San Fernando Valley.</p>
                                    <Link to="/all-services" className="">See Services <i className="fas fa-angle-double-right"/></Link>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Expert
