import React from "react";
import {NavLink} from "react-router-dom";

const GetEstimate = () => {
  return(
      <>
          <hr className={'my-5'}/>
          <h4>Get A Free Estimate</h4>
          <p className={'my-4'}>We are more than happy to give advice on which solution is most suitable for your needs, depending on your Shower Pan Services. Why not ask us to visit your property and discuss the various options with you now. Our advice is free!</p>
          <div className="appointment-area mt-5">
              <div className="row">
                  <div className="col-md-9">
                      <h4 className={'mt-2'}>Need a shower pan service ? Contact Us</h4>
                  </div>
                  <div className="col-md-3 text-md-end text-center">
                      <NavLink to={'/get-appointment'}><button className="btn get" type="button"> Get Appointment</button></NavLink>
                  </div>
              </div>
          </div>
      </>
  )
}
export default GetEstimate