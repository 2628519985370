import React, {useEffect} from "react";
import {Link, NavLink} from "react-router-dom";
import Nav from "./Include/Nav";
import axios from "axios";
import BASEURL from "../../../Constants";

const UserHome = () => {

    const getData = () => {
      axios.get(BASEURL+'/api/client/get-dashboard-data').then(res=>{
          console.log(res)
      })
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <>
            <div className="container my-2 py-5 client-area-section-gap">
                <div className="row">
                    <div className="col-md-3">
                        <Nav/>
                    </div>
                    <div className="col-md-9">
                        <h4>Client Dashboard</h4>
                        <hr/>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h1>05</h1>
                                        <p>Total Appointments</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h1 className={'text-info'}>02</h1>
                                        <p>Upcoming Appointments</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h1 className={'text-warning'}>02</h1>
                                        <p>Pending Appointments</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h1 className={'text-success'}>01</h1>
                                        <p>Completed Appointments</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h1 className={'text-info'}>0</h1>
                                        <p>Pending Payment</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h1 className={'text-success'}>05</h1>
                                        <p>Completed Payment</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserHome