import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import appStore from './../../../Assets/images/appstore.png'
import playStore from './../../../Assets/images/playstore.png'
import alanBtn from "@alan-ai/alan-sdk-web";


const Footer = () =>{
    const alanKey= 'da70f2952a150ea44ca335b918d068a22e956eca572e1d8b807a3e2338fdd0dc/stage'
    const history = useHistory()
    useEffect(()=>{
        alanBtn({
            key: alanKey,
            right: '60px',
            onCommand:(commandData)=> {
                if (commandData.command =='testCommand'){
                    history.push('/client/profile')
                }
            }
        })
    }, [])


    const [relatedService , setRelatedServicenk] = useState([
        {link : 'http://www.alllocaltilestonerestoration.com', name:'Alllocaltilestonerestoration'},
        {link : 'http://www.allseasontrashremoval.com', name:'Allseasontrashremoval.com'},
        {link : 'http://www.alltilestonerestoration.com', name:'Alltilestonerestoration.com'},
        {link : 'http://www.alltrashcleaning.com', name:'Alltrashcleaning.com'},
        {link : 'http://www.alltrashremoval.net', name:'Alltrashremoval.net'},
        {link : 'http://www.everlastpavingstone.com', name:'Everlastpavingstone.com'},
        {link : 'http://www.hopehomecleaningservices.com', name:'Hopehomecleaningservices'},
        {link : 'http://www.localalltilestonerestoration.com', name:'Localalltilestonerestoration'},
        {link : 'http://www.localalltrashcleaning.com', name:'Localalltrashcleaning.com'},
        {link : 'http://www.localalltrashremoval.com', name:'Localalltrashremoval.com'},
        {link : 'http://www.localtilestonerestoration.com', name:'Localtilestonerestoration'},
        {link : 'http://www.localtrashcleaning.com', name:'Localtrashcleaning.com'},
        {link : 'http://www.localtrashremoval.net', name:'Localtrashremoval.net'},
        {link : 'http://www.megaroofing.net/', name:'Megaroofing.net'},
    ])
    const [otherService , setotherService] = useState([
        {link : 'http://www.megatrashremoval.com', name:'Megatrashremoval.com'},
        {link : 'http://www.megatreeremoval.com', name:'Megatreeremoval.com'},
        {link : 'http://www.megatreeservice.com', name:'Megatreeservice.com'},
        {link : 'http://www.powerplumbingdraincleaning.com', name:'Powerplumbingdraincleaning'},
        {link : 'http://www.trashalljunkremoval.com', name:'Trashalljunkremoval.com'},
        {link : 'http://www.lookinghandyman.com', name:'LocalConstructionremodeling'},
        {link : 'http://www.lookinghandyman.com', name:'Lookinghandyman.com'},
        {link : 'http://www.megabusinessdentalgroup.com', name:'Megabusinessdentalgroup'},
        {link : 'http://www.megaclinicadental.com', name:'Megaclinicadental.com'},
        {link : 'http://www.megaclinicamedica.com', name:'Megaclinicamedica.com'},
        {link : 'http://www.megalawgroup.com', name:'Megalawgroup.com'},
        {link : 'http://www.megamedicalclinics.com/', name:'Megamedicalclinics.com'},
        {link : 'http://www.waterdamagefastdry.com/', name:'Waterdamagefastdry.com'},
    ])
    return(
        <>
        <section className="visit">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="box">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 ">
                                    <i className="fas fa-map-marker-alt"/>
                                </div>
                                <div className="flex-grow-1 size ">
                                    <h5 className="text-white">VISIT US:</h5>
                                    <p className="text-center">8444 Reseda Blvd, Northridge, CA 91324, United States</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="box">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <i className=" fas fa-envelope"/>
                                </div>
                                <div className="flex-grow-1 size ">
                                    <h5 className="text-white">EMAIL US:</h5>
                                    <p className="text-center text-white mt-2"><a className={'text-white'} href={'mailto:megahotmop@gmail.com'}>megahotmop@gmail.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="box">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 ">
                                    <i className=" fas fa-phone-alt"/>
                                </div>
                                <div className="flex-grow-1 size ">
                                    <h5 className="text-white">CALL US:</h5>
                                    <p className="text-center text-white mt-2"><a className={'text-white'} href={'callto:8187706891'}>(818) 770 – 6891</a> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*VISIT US end*/}
        {/*footer section start*/}
        <section className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="widget">
                            <h4>
                                RELATED SERVICES
                            </h4>
                            <ul>
                                {relatedService.map((item, index)=>(
                                    <li key={index}><a href={item.link} target={'_blank'} rel={'noreferrer'}>{item.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="widget">
                            <h4>
                                OTHER SERVICES
                            </h4>
                            <ul>
                                {otherService.map((item, index)=>(
                                    <li key={index}><a href={item.link} target={'_blank'} rel={'noreferrer'}>{item.name}</a></li>
                                ))}
                            </ul>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="widget">
                        <h4>
                            DOWNLOAD APP <small>(ANDROID & IOS)</small>
                        </h4>
                        <div className="app-links">
                            <a href={'https://apps.apple.com/us/app/mega-hot-mop/id1516241429'} target={'_blank'} rel="noreferrer"><img src={appStore} alt={'App Store Link'}/></a>
                            <a href={'https://play.google.com/store/apps/details?id=com.jSoft.megahotmop&hl=en_US'} target={'_blank'} rel="noreferrer"><img src={playStore} alt={'Play Store Link'}/></a>
                        </div>


                    </div>
                    <div className="subscribe mt-5 mb-2">
                        <small>Subscribe now and receive weekly newsletter with new tips and exciting offers or discounts.</small>
                    </div>
                    <div className="input-group mb-5">
                        <input type="text" className="form-control" placeholder="Enter Email Address..."/>
                        <button  className="input-group-text" id="basic-addon1">Subscribe</button>
                    </div>
                    <div className="privacy-policy text-end">
                        <Link  to={'/privacy-policy'}><small>Privacy Policy</small></Link>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <section className="footer-below">
        <div className="container">
            <div className="row">
                <div className="col-md-12 py-4">
                    <p className="text-center"><small>Copyright © Sublime Productions Inc, Mega Hot Mop, Mega Hot Mop Inc., <a href={'https://www.megabusinessusa.com'} target={'_blank'} rel="noreferrer">MegabusinessUSA.com</a> , <a href={'https://www.sublimelist.com'} target={'_blank'} rel="noreferrer">Sublimelist.com</a>  2017 - 2020. All rights reserved.</small></p>
                    <p className="text-center"><small>Developed by: Sublime Productions Inc</small></p>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}
export default Footer
