import React, {useEffect, useState} from "react";
import Nav from "./Include/Nav";
import {useParams} from "react-router-dom";
import axios from "axios";
import BASEURL from "../../../Constants";

const AppointmentDetails = () => {

    const [appointmentId] = useState(useParams())
    const [appointment, setAppointment] = useState([])


    const getAppointmentDetails = () => {
        axios.get(BASEURL + '/api/client/get-appointments-details/' + appointmentId.id).then(res => {
            setAppointment(res.data.data)
        })
    }
    useEffect(() => {
        getAppointmentDetails()
    }, [])
    return (
        <>
            <div className="container my-2 py-5 client-area-section-gap">
                <div className="row">
                    <div className="col-md-3">
                        <Nav/>
                    </div>
                    <div className="col-md-9">
                        <h4>Appointment Details</h4>
                        <hr/>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h6>Contact details</h6>
                                    </div>
                                    <div className="card-body">
                                        <table className={'table table-hover table-bordered'}>
                                            <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{appointment.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <td>{appointment.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td>{appointment.email}</td>
                                            </tr>
                                            <tr>
                                                <th>Phone</th>
                                                <td>{appointment.phone}</td>
                                            </tr>
                                            <tr>
                                                <th>Cell</th>
                                                <td>{appointment.cell}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{appointment.company}</td>
                                            </tr>
                                            <tr>
                                                <th>Address</th>
                                                <td>{appointment.address}</td>
                                            </tr>
                                            <tr>
                                                <th>City</th>
                                                <td>{appointment.city}</td>
                                            </tr>
                                            <tr>
                                                <th>State</th>
                                                <td>{appointment.state}</td>
                                            </tr>
                                            <tr>
                                                <th>ZIP Code</th>
                                                <td>{appointment.zip}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-header">
                                        <h6>Payment details</h6>
                                    </div>
                                    <div className="card-body">
                                        <table className={'table table-hover table-bordered'}>
                                            <tbody>
                                            <tr>
                                                <th>Amount</th>
                                                <td>{appointment.details != undefined? '$ '+ appointment.details.price: null}</td>
                                            </tr>
                                            <tr>
                                                <th>Paid Amount</th>
                                                <td>{appointment.transaction != undefined? '$ '+ appointment.transaction.paid_amount: null}</td>
                                            </tr>
                                            <tr>
                                                <th>Payment Time</th>
                                                <td>{appointment.transaction != undefined? appointment.transaction.payment_time: null}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{appointment.transaction != undefined? appointment.transaction.status: null}</td>
                                            </tr>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <td>{appointment.transaction != undefined? appointment.transaction.transaction_id: null}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h6>Booking details</h6>
                                    </div>
                                    <div className="card-body">
                                        <table className={'table table-hover table-bordered'}>
                                            <tbody>
                                            <tr>
                                                <th>Service For</th>
                                                <td>{appointment.service_for}</td>
                                            </tr>
                                            <tr>
                                                <th>Service Type</th>
                                                <td>{appointment.service_type}</td>
                                            </tr>
                                            <tr>
                                                <th>Booking Time</th>
                                                <td>{appointment.date_time}</td>
                                            </tr>
                                            <tr>
                                                <th>Service Status</th>
                                                <td>{appointment.status ==1 ? 'Pending':'Completed'}</td>
                                            </tr>
                                            <tr>
                                                <th>Order Placed at</th>
                                                <td>{appointment.created_at}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-header">
                                        <h6>Service details</h6>
                                    </div>
                                    <div className="card-body">
                                        <table className={'table table-hover table-bordered'}>
                                            <tbody>
                                            <tr>
                                                <th>Type</th>
                                                <td>{appointment.details != undefined? appointment.details.type: null}</td>
                                            </tr>
                                            <tr>
                                                <th>Size</th>
                                                <td>{appointment.details != undefined? appointment.details.size: null}</td>
                                            </tr>
                                            <tr>
                                                <th>Distance</th>
                                                <td>{appointment.details != undefined? appointment.details.distance: null}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default AppointmentDetails