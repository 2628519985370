import React, {useEffect, useState} from "react";
import Nav from "./Include/Nav";
import axios from "axios";
import BASEURL from "../../../Constants";
import {Modal} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";


const Profile = () => {
    useEffect(() => {
        getUserData()
    }, [])

    const [errMsg, setErrMsg] = useState({})
    const [inputData, setInputData] = useState({})
    const handleInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        setInputData(prev => ({...prev, [name]: value}))
    }
    const [isModalOpen, setModalStatus] = useState(false);
    const handleModal = (e) => {
        axios.get(BASEURL+'/api/client/get-user-data-for-edit').then(res=>{
            setInputData(res.data)
            setModalStatus(true)
        })
    }

    const [user, setUser] = useState([]);
    const getUserData = () => {
        axios.get(BASEURL + '/api/client/get-user-data').then(res => {
            setUser(res.data.data)
        })
    }
    const updateProfile = (e) => {
        e.preventDefault()
        axios.put(BASEURL+'/api/client/update-profile', inputData).then(res=>{
            toast.success(res.data.msg);
            setModalStatus(false)
            setInputData({})
            setErrMsg({})
            getUserData()
        }).catch(errors=>{
            if (errors.response.status == 422){
                setErrMsg(errors.response.data.errors)
            }
        })
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container my-2 py-5 client-area-section-gap">
                <div className="row">
                    <div className="col-md-3">
                        <Nav/>
                    </div>
                    <div className="col-md-9">
                        <h4>Profile</h4>
                        <hr/>
                        <table className={'table table-hover table-bordered'}>
                            <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{user.name}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{user.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{user.phone}</td>
                            </tr>
                            <tr>
                                <th>Registration Date</th>
                                <td>{user.created_at}</td>
                            </tr>
                            <tr>
                                <th>Last Update</th>
                                <td>{user.updated_at}</td>
                            </tr>
                            </tbody>
                        </table>
                        <button onClick={(e) => handleModal(e)} className={'btn btn-success'}><i
                            className={'fas fa-edit'}/> Edit Profile
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isModalOpen}
                onHide={() => setModalStatus(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span>Edit Profile</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="login-registration-panel">
                        <form className={'px-4 py-2'}>
                            <label className={'w-100'}>
                                Name
                                <input onChange={handleInput} type={'text'} name={'name'} defaultValue={inputData.name}
                                       className={'form-control mt-2'}/>
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'name' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                            </label>
                            <label className={'w-100 mt-2'}>
                                Email
                                <input onChange={handleInput} type={'email'} name={'email'} defaultValue={inputData.email}
                                       className={'form-control mt-2'}/>
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'email' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                            </label>
                            <label className={'w-100 mt-2'}>
                                Phone
                                <input onChange={handleInput} type={'number'} name={'phone'} defaultValue={inputData.phone}
                                       className={'form-control mt-2'}/>
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'phone' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                            </label>
                            <div className="row justify-content-center">
                                <div className="col-6">
                                    <div className="d-grid">
                                        <button onClick={updateProfile}
                                                className={'btn btn-outline-info mt-3'}>Update Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Profile