import React, {useState} from "react";

import Slider1 from '../../../Assets/images/banner/hot-mop-1.jpg'
import Slider2 from '../../../Assets/images/banner/hot-mop-2.jpg'
import Slider3 from '../../../Assets/images/banner/hot-mop-3.jpg'

const Slider = () =>{
    const [slider] = useState([
        {img : Slider1},
        {img : Slider2},
        {img : Slider3},
    ])
    return(
        <>
            <section className="slider">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"/>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"/>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"/>
                    </div>
                    <div className="carousel-inner">

                        {slider.map((slider, index)=>(
                            <div className={index === 0 ?'carousel-item active':'carousel-item'} key={index}>
                                <img src={slider.img} className="d-block w-100 banner-image"
                                     alt="..."/>
                                    <div className="carousel-caption d-none d-md-block">
                                        <h3 className="text-danger text-center">We are available for </h3>
                                        <h1 className="text-white display-4 banner-h1"> Mega Hot Mop, Shower Pan</h1>
                                        <p className="text-center"><i className="far fa-check-circle text-warning"/> Total
                                            Quality Service & Works</p>
                                        <p className="text-center"><i className="far fa-check-circle text-warning"/> 100%
                                            Pass Inspection</p>
                                        <p className="text-center"><i className="far fa-check-circle text-warning"/> 3 year Warranty </p>
                                    </div>
                            </div>
                        ))}



                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section>
            <section className="hot-contact-area">
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="text-white"><i className="fas fa-phone-square-alt text-warning"/> EMERGENCY
                                HOT MOP & SHOWER PAN</h4>
                            <p className="text-white border-top" style={{width: 'fit-content'}}>We're open 7 am to 10 pm
                                because Hot Mop emergencies don't wait.<br/>
                                <a href="/" className="text-decoration-none text-warning"><i className="fas fa-phone-volume"/> Call us now</a>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <h4 className="text-end text-white"><i className="fas fa-phone-square text-warning"/> SHOWER
                                PAN & HOT MOP SERVICE</h4>
                            <p className="text-end text-white  border-top float-end" style={{width: 'fit-content'}}>Do you need
                                shower pan services? <br/>
                                <a href="/" className="text-decoration-none text-warning"><i className="fas fa-phone-volume"/> Call Us</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Slider
