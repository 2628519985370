import React, {useEffect, useState} from "react";
import BathTub from "../../../../Assets/images/bathtub.jpg";
import axios from "axios";
import BASEURL from "../../../../Constants";
import {Link, useHistory} from "react-router-dom";
import Slider from "../../Includes/Slider";
import {Helmet} from "react-helmet";
const Login = () => {
    const history = useHistory();
    useEffect(()=>{
        if (localStorage.token != undefined){
            history.push('/client-area')
        }
    }, [])


    const [errMsg, setErrMsg] =useState({})
    const [inputData, setInputData] = useState({})
    const handleInput = (e) =>{
        let name = e.target.name
        let value = e.target.value
        setInputData(prev=>({...prev, [name]: value}))
    }
    const login = (e) =>{
        e.preventDefault()
        axios.post(BASEURL+'/api/client/login', inputData).then(res=>{
            localStorage.name = res.data.name
            localStorage.email = res.data.email
            localStorage.token = res.data.token
            history.push('/client-area')
            window.location.reload()
        }).catch(errors=>{
            if(errors.response.status == 422){
                setErrMsg(errors.response.data.errors)
            }
        })
    }

  return(
      <>
          <Helmet>
              <title>Login | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
          </Helmet>
          <Slider/>
          <div className="container text-center mt-5">
              <div className="col-md-12">
                  <h1 className={'text-center text-center'}>Login</h1>
              </div>
          </div>
          <div className="container my-5">
              <div className="row justify-content-center">
                  <div className="col-md-6">
                      <div className="card">
                          <div className="card-header">
                              <h4>Login</h4>
                          </div>
                          <div className="card-body">
                              <form className={'px-4 py-2'}>
                                  <label className={'w-100'}>
                                      Email/Phone
                                      <input onChange={handleInput} type={'text'} name={'email'} className={'form-control mt-2'}/>
                                      {Object.entries(errMsg).map(([key, val]) => (
                                          <p  key={key}>
                                              <small
                                                  className="text-danger validation-error-message">
                                                  {key == 'email' ? val : ''}
                                              </small>
                                          </p>
                                      ))}
                                  </label>
                                  <label className={'w-100 mt-2'}>
                                      Password
                                      <input onChange={handleInput} type={'password'} name={'password'} className={'form-control mt-2'}/>
                                      {Object.entries(errMsg).map(([key, val]) => (
                                          <p  key={key}>
                                              <small
                                                  className="text-danger validation-error-message">
                                                  {key == 'password' ? val : ''}
                                              </small>
                                          </p>
                                      ))}
                                  </label>
                                  <div className="row justify-content-center">
                                      <div className="col-6">
                                          <div className="d-grid">
                                              <button onClick={login} className={'btn btn-outline-info mt-3'}>Login</button>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                              <p className={'text-center'}>Not Registered? Please <Link to={'/register'}>Register</Link></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}
export default Login