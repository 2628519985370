import React, {useState} from "react";
import BathTub from "../../../Assets/images/bathtub.jpg";
import {Link} from "react-router-dom";
import Img1 from "../../../Assets/images/service/waterproofing.jpg"
import Img2 from "../../../Assets/images/service/Hot-Mop-Balconies.jpg"
import Img3 from "../../../Assets/images/service/Hot-Mop-Staircase-.jpg"
import Img4 from "../../../Assets/images/service/hot-mop-planter-box.jpg"
import Img5 from "../../../Assets/images/service/Hot-Mop-Decks.jpg"
import Img6 from "../../../Assets/images/service/waterproofing.jpg"
import Img7 from "../../../Assets/images/service/Residential-.jpg"
import Img8 from "../../../Assets/images/service/Commercial-.jpg"
import Slider from "../Includes/Slider";
import {Helmet} from "react-helmet";


const Service = () =>{
    const [services, setServices] = useState([
        {headline : 'HOT MOP SHOWER PAN', img: Img1, url:'/services/hot-mop-shower-pan', text:'Best customer service, Affordable prices, and the most dependable Hot Mop Shower Pan' },
        {headline : 'HOT MOP BALCONIES',img: Img2, url:'/services/hot-mop-balconies', text:'Best customer service, Affordable prices, and the most dependable Hot Mop Balconies'},
        {headline : 'HOT MOP STAIR', img: Img3, url:'/services/hot-mop-stair', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Staircase'},
        {headline : 'HOT MOP PLANTER BOXES', img: Img4, url:'/services/hot-mop-planter-boxes', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Planter Boxes'},
        {headline : 'HOT MOP DECKS', img: Img5, url:'/services/hot-mop-decks', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Decks'},
        {headline : 'HOT MOP WATERPROOFING', img: Img6, url:'/services/water-proofing', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Waterproofing'},
        {headline : 'HOT MOP RESIDENTIAL', img: Img7, url:'/services/hot-mop-residential', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Residential'},
        {headline : 'HOT MOP COMMERCIAL', img: Img8, url:'/services/hot-mop-commercial', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Commercial'},
    ])
    return(
      <>
          <Helmet>
              <title>Our Services | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
          </Helmet>
          <Slider/>
          <div className="container text-center mt-5">
              <div className="col-md-12">
                  <h1 className={'text-center text-center'}>All Services</h1>
              </div>
          </div>
          <div className="container mt-5">
              <div className="row">
                  {services.map((item, index)=>(
                      <div className="col-md-4 mb-4" key={index}>
                          <div className="card service-card">
                              <Link to={item.url}><img src={item.img} className={'card-img-top service-card-img-top'} alt={item.headline} /> </Link>
                              <div className="card-header">
                                  <Link to={item.url}>{item.headline}</Link>
                              </div>
                              <div className="card-body">
                                  <p>{item.text}</p>
                                <Link to={item.url}><button className={'btn btn-outline-secondary text-uppercase mt-4 float-end'}>Read More</button></Link>
                              </div>
                          </div>
                      </div>
                  ))}

              </div>
          </div>
      </>
    )
}
export default Service
