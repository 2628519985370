import React from "react";

const About = () =>{
    return(
        <>
            <section className="parallax-1">
                <h2 className="text-center text-white parallax-1-p">Mega Hot Mop, We are unique</h2>
            </section>
            <section id="contact" className="contact">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="row aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                        <div className="col-lg-6">
                            <h2>About Us</h2>
                            <p>Our well experienced experts perform all Hot Mop services including:</p>
                            <ul>
                                <li><i className="fas fa-check">Shower Pan</i></li>
                                <li><i className="fas fa-check">Water Proofing</i></li>
                                <li><i className="fas fa-check">Drains</i></li>
                                <li><i className="fas fa-check">Stair</i></li>
                                <li><i className="fas fa-check">Planter Boxes</i></li>
                            </ul>
                            <p>In Addition to our commitment towards excellence our advantages are :</p>
                            <ul>
                                <li><i className="fas fa-check">Free Estimates</i></li>
                                <li><i className="fas fa-check">Working since 1990</i></li>
                                <li><i className="fas fa-check">Total Quality Services and Works</i></li>
                                <li><i className="fas fa-check">100% Pass Inspection</i></li>
                                <li><i className="fas fa-check">3 Years Warranty</i></li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <h2>REQUEST SERVICE</h2>
                            <p>Enter your contact details here to help us serve you better & faster.</p>
                            <form className="email-form">
                                <div className="row">
                                    <div className="col form-group">
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required=""/>
                                    </div>
                                    <div className="col form-group">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required=""/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" name="phone" id="phone" placeholder="Phone" required=""/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required=""/>
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" name="message" rows="5" placeholder="Message" required=""/>
                                </div>
                                <p>Please upload your Hot Mop Shower Pan images:</p>
                                <div className="input-group mb-3">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="inputGroupFile01"/>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit">GET QUOTE</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default About
