import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import axios from "axios";
import BASEURL from "./Constants";

const Auth = ({ component : Component, ...rest}) => {
    const [auth, setAuth] = useState(false);
    useEffect(()=>{
      const token = localStorage.token
      if (token){
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      let url = window.location.pathname
     if (url=='/get-appointment' || url=='/client-area' || url== '/client/profile' || url== '/client/appointment'|| url== '/client/change-password' || url == '/payment'){
         axios.post(BASEURL+'/api/client/check-auth').then(res=>{
             if (res.data.msg == true){
                 setAuth(res.data.msg)
             }else {
                 localStorage.removeItem('token')
                 localStorage.removeItem('name')
                 localStorage.removeItem('email')
             }
         })
     }

  },[])

  const token = localStorage.token
  return(
      <Route
          {...rest}
          render={props=>
          token != undefined ? (
              <Component {...props} />
          ):(
              <Redirect
                to={{
                  pathname:"/login",
                    state: {from: props.location}
                }}
                />
          )
          }
      />
  )
}
export default Auth