import React from "react";
import {NavLink} from "react-router-dom";
const Nav = () => {
  return(
      <div className="client-nav mb-md-0 mb-4">
          <NavLink to={'/client-area'}>Dashboard</NavLink>
          <NavLink to={'/client/profile'}>Profile</NavLink>
          <NavLink to={'/client/change-password'}>Change Password</NavLink>
          <NavLink to={'/client/appointment'}>Appointments</NavLink>
          <NavLink to={'/client/schedule'}>Schedule</NavLink>
          <NavLink to={'/client/payment'}>Payment</NavLink>
      </div>
  )
}
export default Nav