import React from "react"
import BathTub from "../../../../Assets/images/bathtub.jpg";
import Nav from "./Include/Nav";
import GetEstimate from "./Include/GetEstimate";
import Img1 from "../../../../Assets/images/service/IMG_1715.jpg";
import Slider from "../../Includes/Slider";
import {Helmet} from "react-helmet";
const Stair = () => {
  return(
      <>
        <Helmet>
          <title>Hot Mop Stair | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
        </Helmet>
        <Slider/>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-3">
              <Nav/>
            </div>
            <div className="col-md-9">
              <h4 className={'mb-4'}>Hot Mop Stair</h4>
              <p>There are three main components in a typical staircase: stringers, treads and risers. Stringers, typically cut from 2 x 12s, are the sloped boards that support the other components and carry the weight of people walking on the stairs. They’re typically spaced 16 in. on center. When determining the staircase width, remember that wider is better. “Wide staircases are more comfortable and safer to walk on. seldom build one less than 4 ft. wide, and prefer them a bit wider.</p>
              <div className="row">
                <div className="col-md-8 mt-5">
                  <h4>Key Benefit of Service </h4>
                  <p className={'my-4'}>Whether you require shower pan service, call us. we take pride in
                    the work we perform with our dedicated plumbers.</p>
                  <div className="row">
                    <div className="col-6">
                      <p><i className="fas fa-check"/> Locally Own Operated</p>
                      <p><i className="fas fa-check"/> Bonded and insured team</p>
                      <p><i className="fas fa-check"/> Upfront, Flat Rate Pricing</p>
                    </div>
                    <div className="col-6">
                      <p><i className="fas fa-check"/> Workmanship guaranteed</p>
                      <p><i className="fas fa-check"/> High skilled experts</p>
                      <p><i className="fas fa-check"/> Friendly, professionals</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-5">
                  <img src={Img1} alt={'Hot Mop Shower Pan'} className={'img-thumbnail'}/>
                </div>
              </div>
              <GetEstimate/>
            </div>
          </div>
        </div>
      </>
  )
}
export default Stair