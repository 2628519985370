import React, {useEffect, useState} from "react";
import BathTub from "../../../Assets/images/bathtub.jpg";
import Select from "react-select";
import axios from "axios";
import BASEURL from "../../../Constants";
import {toast, ToastContainer} from "react-toastify";
import {useHistory} from "react-router-dom";

const Appointment = () => {
    const history = useHistory()
    useEffect(() => {
        if (localStorage.appointment_id != undefined) {
            history.push('/payment')
        }
    }, []);


    const [date, setDate] = useState([
        {value: 1, label: 1},
        {value: 2, label: 2},
        {value: 3, label: 3},
        {value: 4, label: 4},
        {value: 5, label: 5},
        {value: 6, label: 6},
        {value: 7, label: 7},
        {value: 8, label: 8},
        {value: 9, label: 9},
        {value: 10, label: 10},
        {value: 11, label: 11},
        {value: 12, label: 12},
        {value: 13, label: 13},
        {value: 14, label: 14},
        {value: 15, label: 15},
        {value: 16, label: 16},
        {value: 17, label: 17},
        {value: 18, label: 18},
        {value: 19, label: 19},
        {value: 20, label: 20},
        {value: 21, label: 21},
        {value: 22, label: 22},
        {value: 23, label: 23},
        {value: 24, label: 24},
        {value: 25, label: 25},
        {value: 26, label: 26},
        {value: 27, label: 27},
        {value: 28, label: 28},
        {value: 29, label: 29},
        {value: 30, label: 30},
        {value: 31, label: 31},
    ]);
    const [month, setMonth] = useState([
        {value: '1', label: 'January'},
        {value: '2', label: 'February'},
        {value: '3', label: 'March'},
        {value: '4', label: 'April'},
        {value: '5', label: 'May'},
        {value: '6', label: 'June'},
        {value: '7', label: 'July'},
        {value: '8', label: 'August'},
        {value: '9', label: 'September'},
        {value: '10', label: 'October'},
        {value: '11', label: 'November'},
        {value: '12', label: 'December'},
    ])
    const [year, setYear] = useState([
        {value: 2022, label: 2022},
        {value: 2023, label: 2023},
        {value: 2024, label: 2024},
        {value: 2025, label: 2025},
        {value: 2026, label: 2026},
    ])

    const [time, setTime] = useState([
        {value: '09:00 AM', label: '09:00 AM'},
        {value: '10:00 AM', label: '10:00 AM'},
        {value: '11:00 AM', label: '11:00 AM'},
        {value: '12:00 AM', label: '12:00 AM'},
        {value: '01:00 PM', label: '01:00 PM'},
        {value: '02:00 PM', label: '02:00 PM'},
        {value: '03:00 PM', label: '03:00 PM'},
        {value: '04:00 PM', label: '04:00 PM'},
        {value: '05:00 PM', label: '05:00 PM'},
        {value: '06:00 PM', label: '06:00 PM'},
        {value: '07:00 PM', label: '07:00 PM'},
        {value: '08:00 PM', label: '08:00 PM'},
        {value: '09:00 PM', label: '09:00 PM'},
        {value: '10:00 PM', label: '10:00 PM'},
    ])

    const [inputData, setInputData] = useState({})

    const handleInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        setInputData(prev => ({...prev, [name]: value}))
    }

    const [showForm, setShowForm] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const handleFormShow = () => {
        if (inputData.owner_company != undefined){
            setShowErrorMessage(true)
            if (showForm) {
                setShowForm(false)
            } else {
                setShowForm(true)
            }
        }else{
            setShowErrorMessage(true)
        }


    }
    const handleOwner = (e) => {
        let owner_company = e.target.value
        setInputData(prev => ({...prev, owner_company: owner_company}))
        console.log(inputData)
    }

    const handleSelectInput = (selectedOption, e) => {
        let name = e.name
        setInputData(prev => ({...prev, [name]: selectedOption.value}))
    }


    const [errMsg, setErrMsg] = useState({})
    const saveAppointment = (e) => {
        e.preventDefault()
        axios.post(BASEURL + '/api/client/appointment', inputData).then(res => {
            localStorage.appointment_id = res.data.appointment_id
            toast.success(res.data.msg);
            history.push('/payment')
        }).catch(function (errors) {
            if (errors.response.status == 422) {
                setErrMsg(errors.response.data.errors)
            }
        })
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container-fluid g-0 position-relative">
                <div className="about-banner-overly"/>
                <img src={BathTub} className={'img-fluid about-banner'} alt={'About Us'}/>
                <div className="about-overly">
                    <h1 className={'text-center text-white'}>Get Appointment</h1>
                </div>
            </div>
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <h2 className={'text-center'}>Fill Out the Appointment Request Form Online and We’ll Contact You!</h2>

                        {showForm == false ?
                            <div className="row justify-content-center">
                                <div className="col-md-6 mt-5 text-center">
                                    <div className="card">
                                        <div className="card-header" style={{background: '#0dcaf036'}}>
                                            <p><strong>Is service for Owner or Contactor</strong></p>
                                        </div>
                                        <div className="card-body">
                                            <div className="row justify-content-center">
                                                <div className="col-6">
                                                    <input value={1} onChange={handleOwner} type={'radio'} name={'order_type'}/> Owner
                                                </div>
                                                <div className="col-6">
                                                    <input value={2} onChange={handleOwner} type={'radio'} name={'order_type'}/> Contactor
                                                </div>
                                                {showErrorMessage ?  <div className="col-md-12">
                                                    <p className={'text-danger text-center'}><small>Please Select Owner or Contractor</small> </p>
                                                </div> : null}

                                                <div className="col-6 mt-2">
                                                    <div className="d-grid">
                                                        <button onClick={handleFormShow} className={'btn btn-info btn-sm'}>Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="main-form-container">
                                <div className="row my-5 appointment-form">
                                    <div className="col-md-6">
                                        <label className={'my-3'}>IS THIS SERVICE FOR</label> <br/>
                                        <div className="service-for">
                                            <input type={'radio'} onClick={handleInput} name={'service_for'} value={'Home'}/> <span>Your Home</span>
                                            <input type={'radio'} onClick={handleInput} name={'service_for'} value={'Business'}/> <span>Your Business</span>
                                            {Object.entries(errMsg).map(([key, val]) => (
                                                <p key={key}>
                                                    <small
                                                        className="text-danger validation-error-message">
                                                        {key == 'service_for' ? val : ''}
                                                    </small>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>SELECT A CONVENIENT DAY FOR YOUR SERVICE:</label>
                                        <div className="row">
                                            <div className="col-4">
                                                <Select
                                                    options={date}
                                                    placeholder={'Date'}
                                                    name={'date'}
                                                    onChange={(selectedOption, event) => handleSelectInput(selectedOption, event)}
                                                />
                                                {Object.entries(errMsg).map(([key, val]) => (
                                                    <small
                                                        className="text-danger validation-error-message"
                                                        key={key}>{key == 'date' ? val : ''}
                                                    </small>
                                                ))}
                                            </div>
                                            <div className="col-4">
                                                <Select
                                                    options={month}
                                                    placeholder={'Month'}
                                                    name={'month'}
                                                    onChange={(selectedOption, event) => handleSelectInput(selectedOption, event)}
                                                />
                                                {Object.entries(errMsg).map(([key, val]) => (
                                                    <small
                                                        className="text-danger validation-error-message"
                                                        key={key}>{key == 'month' ? val : ''}
                                                    </small>
                                                ))}
                                            </div>
                                            <div className="col-4">
                                                <Select
                                                    options={year}
                                                    placeholder={'Year'}
                                                    name={'year'}
                                                    onChange={(selectedOption, event) => handleSelectInput(selectedOption, event)}
                                                />
                                                {Object.entries(errMsg).map(([key, val]) => (
                                                    <small
                                                        className="text-danger validation-error-message"
                                                        key={key}>{key == 'year' ? val : ''}
                                                    </small>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>SELECT A CONVENIENT TIME</label> <br/>
                                        <Select
                                            options={time}
                                            placeholder={'Select Time'}
                                            name={'time'}
                                            onChange={(selectedOption, event) => handleSelectInput(selectedOption, event)}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'time' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>YOUR FIRST NAME AND LAST NAME</label> <br/>
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'name'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'name' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>YOUR COMPANY</label> <br/>
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'company'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'company' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>JOBSITE ADDRESS</label> <br/>
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'address'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'address' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-4">
                                        <label className={'my-3'}>City</label> <br/>
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'city'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'city' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-4">
                                        <label className={'my-3'}>State</label> <br/>
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'state'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'state' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-4">
                                        <label className={'my-3'}>Zip Code</label> <br/>
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'zip'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'zip' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>JOBSITE PHONE NUMBER</label> <br/>
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'phone'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'phone' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>CELL PHONE NUMBER</label> <br/>
                                        <input
                                            type={'text'}
                                            className={'form-control'}
                                            name={'cell'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'cell' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>EMAIL ADDRESS</label> <br/>
                                        <input
                                            type={'email'}
                                            className={'form-control'}
                                            name={'email'}
                                            onChange={handleInput}
                                        />
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'email' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>SERVICE TYPE</label> <br/>
                                        <div className="appointment-service-type">
                                            <input type={'radio'} value={'Hot Mop'} onClick={handleInput} name={'service_type'}/> <span>Hot Mop</span>
                                            <input type={'radio'} value={'Hot Mop Shower Pan'} onClick={handleInput} name={'service_type'}/> <span>Hot Mop Shower Pan</span>
                                            <input type={'radio'} value={'Hot Mop Balconies'} onClick={handleInput} name={'service_type'}/> <span>Hot Mop Balconies</span>
                                            <input type={'radio'} value={'Hot Mop Stair'} onClick={handleInput} name={'service_type'}/> <span>Hot Mop Stair</span>
                                            <input type={'radio'} value={'Hot Mop Commercial'} onClick={handleInput} name={'service_type'}/> <span>Hot Mop Commercial</span>
                                            <input type={'radio'} value={'Hot Mop Residential'} onClick={handleInput} name={'service_type'}/> <span>Hot Mop Residential</span>
                                            <input type={'radio'} value={'Other'} onClick={handleInput} name={'service_type'}/> <span>Other (Specify below)</span>
                                        </div>
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'service_type' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>OTHERS</label> <br/>
                                        <textarea className={'form-control'} name={'others'} onChange={handleInput}/>
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'others' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>BRIEF DESCRIPTION OF PROBLEM:</label> <br/>
                                        <textarea className={'form-control'} name={'description'} onChange={handleInput}/>
                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'description' ? val : ''}
                                            </small>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        <label className={'my-3'}>UPLOAD PHOTO</label> <br/>
                                        <input type={'file'} className={'form-control'}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label className={'my-3'}>SUBSCRIBE TO OUR NEWSLETTER</label> <br/>
                                        <input type={'checkbox'}/>
                                        <span> Yes, I would like to receive occasional hints and tips via email. (Email addresses will not be shared with any other company.)</span>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-3">
                                        <div className="d-grid">
                                            <button className={'btn get'} onClick={handleFormShow}>Back</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-grid">
                                            <button className={'btn get'} onClick={saveAppointment}>REQUEST AN APPOINTMENT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                    </div>
                </div>
            </div>
        </>
    )
}
export default Appointment
