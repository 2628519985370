import React from "react";
import Img1 from '../../../../Assets/images/service/Shower-Pan-IMG_7785.jpg'
import Nav from "./Include/Nav";
import GetEstimate from "./Include/GetEstimate";
import Slider from "../../Includes/Slider";
import {Helmet} from "react-helmet";
const ShowerPan = () => {
    return (
        <>
            <Helmet>
                <title>Hot Mop Shower Pan | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
            </Helmet>
            <Slider/>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-3">
                     <Nav/>
                    </div>
                    <div className="col-md-9">
                        <h4 className={'mb-4'}>Hot Mop Shower Pan</h4>
                        <p>We offer our clients Shower Pan services to provide with best customer service, affordable
                            price. Our professionals will inform you on the things that you can do to have the pan ready
                            for us to come in and do our thing.</p>
                        <p className={'mt-4'}>One the area is completely ready and has been prepped correctly we will
                            then begin the shower pan installation process for you. Contact the team today for all your
                            shower pan requirements including the drains, walk in showers, soap & shampoo niches,
                            windows, tubs, spas, ledges, curbs, dams and benches or shower seats. Let us ensure you get
                            the best installation possible!</p>
                        <div className="row">
                            <div className="col-md-4 mt-5">
                                <img src={Img1} alt={'Hot Mop Shower Pan'} className={'img-thumbnail'}/>
                            </div>
                            <div className="col-md-8 mt-5">
                                <h4>Key Benefit of Service </h4>
                                <p className={'my-4'}>Whether you require shower pan service, call us. we take pride in
                                    the work we perform with our dedicated plumbers.</p>
                                <div className="row">
                                    <div className="col-6">
                                        <p><i className="fas fa-check"/> Locally Own Operated</p>
                                        <p><i className="fas fa-check"/> Bonded and insured team</p>
                                        <p><i className="fas fa-check"/> Upfront, Flat Rate Pricing</p>
                                    </div>
                                    <div className="col-6">
                                        <p><i className="fas fa-check"/> Workmanship guaranteed</p>
                                        <p><i className="fas fa-check"/> High skilled experts</p>
                                        <p><i className="fas fa-check"/> Friendly, professionals</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GetEstimate/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ShowerPan