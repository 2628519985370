import React, {useEffect} from "react";
import {BrowserRouter, Route, useHistory} from "react-router-dom";
import './App.css';
import Home from "./Components/Frontend/Pages/Home";
import About from "./Components/Frontend/Pages/About";
import NavBar from "./Components/Frontend/Includes/NavBar";
import Footer from "./Components/Frontend/Includes/Footer";
import Service from "./Components/Frontend/Pages/Service";
import Payment from "./Components/Frontend/Pages/Payment";
import Schedule from "./Components/Frontend/Pages/Schedule";
import Contact from "./Components/Frontend/Pages/Contact";
import Appointment from "./Components/Frontend/Pages/Appointment";
import PrivacyPolicy from "./Components/Frontend/Pages/PrivacyPolicy";
import ShowerPan from "./Components/Frontend/Pages/ServiceList/ShowerPan";
import Balconies from "./Components/Frontend/Pages/ServiceList/Balconies";
import Stair from "./Components/Frontend/Pages/ServiceList/Stair";
import PlanterBoxes from "./Components/Frontend/Pages/ServiceList/PlanterBoxes";
import Decks from "./Components/Frontend/Pages/ServiceList/Decks";
import Waterproofing from "./Components/Frontend/Pages/ServiceList/Waterproofing";
import Residential from "./Components/Frontend/Pages/ServiceList/Residential";
import Commercial from "./Components/Frontend/Pages/ServiceList/Commercial";
import Auth from "./Auth";
import UserHome from "./Components/Frontend/UserArea/UserHome"
import Profile from "./Components/Frontend/UserArea/Profile";
import ChangePassword from "./Components/Frontend/UserArea/ChangePassword";
import ClientAppointment from "./Components/Frontend/UserArea/ClientAppointment"
import Login from "./Components/Frontend/Pages/Auth/Login";
import Register from "./Components/Frontend/Pages/Auth/Register";
import PayNow from "./Components/Frontend/PayNow";
import AppointmentDetails from "./Components/Frontend/UserArea/AppointmentDetails";
import ClientSchedule from "./Components/Frontend/UserArea/Schedule"
import ClientPayment from "./Components/Frontend/UserArea/Payment"
import ScrollToTop from "react-scroll-to-top"

function App() {

  return (
    <BrowserRouter>
        <NavBar/>
        <div className="App">
            <Route exact path={'/'} component={Home} />
            <Route path={'/about-us'} component={About} />
            <Route path={'/all-services'} component={Service} />
            <Route path={'/schedule'} component={Schedule} />
            <Route path={'/contact-us'} component={Contact} />
            <Route path={'/privacy-policy'} component={PrivacyPolicy} />
            <Route exact path={'/services/hot-mop-shower-pan'} component={ShowerPan} />
            <Route path={'/services/hot-mop-balconies'} component={Balconies} />
            <Route path={'/services/hot-mop-stair'} component={Stair} />
            <Route path={'/services/hot-mop-planter-boxes'} component={PlanterBoxes} />
            <Route path={'/services/hot-mop-decks'} component={Decks} />
            <Route path={'/services/water-proofing'} component={Waterproofing} />
            <Route path={'/services/hot-mop-residential'} component={Residential} />
            <Route path={'/services/hot-mop-commercial'} component={Commercial} />
            <Route path={'/login'} component={Login} />
            <Route path={'/register'} component={Register} />


            <Auth path={'/get-appointment'} component={Appointment} />
            <Auth exact path={'/client-area'} component={UserHome} />
            <Auth path={'/client/profile'} component={Profile} />
            <Auth path={'/client/appointment'} component={ClientAppointment} />
            <Auth path={'/client/appointment-details/:id'} component={AppointmentDetails} />
            <Auth path={'/client/change-password'} component={ChangePassword} />
            <Auth path={'/client/schedule'} component={ClientSchedule} />
            <Auth path={'/client/payment'} component={ClientPayment} />
            <Route path={'/payment'} component={Payment} />
            <Route path={'/pay-now'} component={PayNow} />
        </div>
        <Footer/>
        <ScrollToTop
            smooth
            top={300}
            color={'white'}
        />
    </BrowserRouter>

  );
}

export default App;
