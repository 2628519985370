import React from "react";
import BathTub from "../../../../Assets/images/bathtub.jpg";
import Nav from "./Include/Nav";
import Img1 from "../../../../Assets/images/service/Hot_Mop_planters.jpg";
import GetEstimate from "./Include/GetEstimate";
import Slider from "../../Includes/Slider";
import {Helmet} from "react-helmet";

const PlanterBoxes = () => {
  return(
      <>
          <Helmet>
              <title>Hot Mop Planter Boxes | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
          </Helmet>
          <Slider/>
          <div className="container my-5">
              <div className="row">
                  <div className="col-md-3">
                      <Nav/>
                  </div>
                  <div className="col-md-9">
                      <h4 className={'mb-4'}>Hot Mop Planter Boxes</h4>
                      <p>Today’s indoor planter design has become extremely creative, allowing for green thumbs of all shades to incorporate plants in the home. When shopping for indoor planters and pots, choose one that not only fits with your decor, but work with plants that require maintenance levels compatible with your lifestyle. With so many decorative planters available, you can easily get away with growing even the simplest plant varieties, while still impressing your friends and family! Take your pick of colors, shapes and materials: ceramic, metal, plastic, cement, you name it, there’s an indoor flower pot made of it. With a little help from the information below, it’s easy to pick a succulent planter, herb pot or hanging planter that’s functional and stylish for every room in your home.</p>
                      <div className="row">
                          <div className="col-md-4 mt-5">
                              <img src={Img1} alt={'Hot Mop Shower Pan'} className={'img-thumbnail'}/>
                          </div>
                          <div className="col-md-8 mt-5">
                              <h4>Key Benefit of Service </h4>
                              <p className={'my-4'}>Whether you require shower pan service, call us. we take pride in
                                  the work we perform with our dedicated plumbers.</p>
                              <div className="row">
                                  <div className="col-6">
                                      <p><i className="fas fa-check"/> Locally Own Operated</p>
                                      <p><i className="fas fa-check"/> Bonded and insured team</p>
                                      <p><i className="fas fa-check"/> Upfront, Flat Rate Pricing</p>
                                  </div>
                                  <div className="col-6">
                                      <p><i className="fas fa-check"/> Workmanship guaranteed</p>
                                      <p><i className="fas fa-check"/> High skilled experts</p>
                                      <p><i className="fas fa-check"/> Friendly, professionals</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <GetEstimate/>
                  </div>
              </div>
          </div>
      </>
  )
}
export default PlanterBoxes