import React, {useState} from "react";
import {Link} from "react-router-dom";
import shower from './../../../Assets/images/hot-mop/CUSTOME-SHOWER-HOT-MOP.jpg'
import residencial from './../../../Assets/images/hot-mop/RESIDENTIAL-HOT-MOP.jpg'
import showertab from './../../../Assets/images/hot-mop/SHOWER-TAB-HOT-MOP.jpg'
import commarcial from './../../../Assets/images/service/Commercial-.jpg'
import balconie from './../../../Assets/images/service/Hot-Mop-Balconies.jpg'
import decks from './../../../Assets/images/service/Hot-Mop-Decks.jpg'
import planer from './../../../Assets/images/service/hot-mop-planter-box.jpg'
import waterproofing from './../../../Assets/images/service/waterproofing.jpg'
import stair from './../../../Assets/images/service/Hot-Mop-Staircase-.jpg'

const Services = () =>{
    const [services, setServices] = useState([
        {headline : 'HOT MOP SHOWER PAN', img: shower,  url:'/services/hot-mop-shower-pan', text:'Best customer service, Affordable prices, and the most dependable Hot Mop Shower Pan' },
        {headline : 'HOT MOP BALCONIES', img: balconie, url:'/services/hot-mop-balconies', text:'Best customer service, Affordable prices, and the most dependable Hot Mop Balconies'},
        {headline : 'HOT MOP STAIR', img: stair, url:'/services/hot-mop-stair', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Staircase'},
        {headline : 'HOT MOP PLANTER BOXES', img: planer,  url:'/services/hot-mop-planter-boxes', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Planter Boxes'},
        {headline : 'HOT MOP DECKS', img: decks,  url:'/services/hot-mop-decks', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Decks'},
        {headline : 'HOT MOP WATERPROOFING', img: waterproofing,  url:'/services/water-proofing', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Waterproofing'},
        {headline : 'HOT MOP RESIDENTIAL', img: residencial,  url:'/services/hot-mop-residential', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Residential'},
        {headline : 'HOT MOP COMMERCIAL', img: commarcial,  url:'/services/hot-mop-commercial', text: 'Best customer service, Affordable prices, and the most dependable Hot Mop Commercial'},
    ])
    return(
        <section id="services" className="services">
            <div className="container">
                <h1 className="text-center">Hot Mop & Shower Pan Services </h1>
                <p className="text-center">Mega Hot Mop does Hot Mop & Shower Pan services around Los Angeles County &
                    San Fernando Valley.</p>
                <div className="row justify-content-center">
                    {services.map((item, index)=>(
                        <div className="col-lg-4 col-md-6" key={index}>
                            <div className="icon-box aos-init aos-animate" data-aos="fade-up">
                                <div className="icon"><i className="fas fa-adjust"/></div>
                                <h4 className="title"><Link to={item.url}>{item.headline}</Link></h4>
                                <Link to={item.url}><img src={item.img} className={'front-img-service img-thumbnail'} alt={'Mega hot mop Services'}/></Link>
                                <p className="description">{item.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default Services
