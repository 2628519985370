import React from "react";
import BathTub from "../../../../Assets/images/bathtub.jpg";
import Nav from "./Include/Nav";
import Img1 from "../../../../Assets/images/service/Commercial-.jpg";
import GetEstimate from "./Include/GetEstimate";
import Slider from "../../Includes/Slider";
import {Helmet} from "react-helmet";

const Commercial = () => {
    return (
        <>
            <Helmet>
                <title>Hot Mop Commercial | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
            </Helmet>
            <Slider/>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-3">
                        <Nav/>
                    </div>
                    <div className="col-md-9">
                        <h4 className={'mb-4'}>Hot Mop Commercial</h4>
                        <p>Mega Hot Mop / Shower Pan, does warranted Commercial Hot Mop services around Los Angeles
                            County & San Fernando Valley. </p>
                        <p className={'my-4'}>All Season Mega Hot Mop Service, we are aware, reliable, and affordable
                            Hot Mop | Shower Pan in South California.</p>
                        <p>We have made it our priority to provide the best customer service, affordable prices, and the
                            most dependable Hot Mop | Shower Pan San Fernando Valley, Los Angeles County, Ventura
                            County, Riverside County, Orange County, Ventura County.</p>
                        <div className="row">
                            <div className="col-md-8 mt-5">
                                <h4>Key Benefit of Service </h4>
                                <p className={'my-4'}>Whether you require shower pan service, call us. we take pride in
                                    the work we perform with our dedicated plumbers.</p>
                                <div className="row">
                                    <div className="col-6">
                                        <p><i className="fas fa-check"/> Locally Own Operated</p>
                                        <p><i className="fas fa-check"/> Bonded and insured team</p>
                                        <p><i className="fas fa-check"/> Upfront, Flat Rate Pricing</p>
                                    </div>
                                    <div className="col-6">
                                        <p><i className="fas fa-check"/> Workmanship guaranteed</p>
                                        <p><i className="fas fa-check"/> High skilled experts</p>
                                        <p><i className="fas fa-check"/> Friendly, professionals</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-5">
                                <img src={Img1} alt={'Hot Mop Shower Pan'} className={'img-thumbnail'}/>
                            </div>
                        </div>
                        <GetEstimate/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Commercial