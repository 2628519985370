import React, {useEffect} from "react";
import  '../../../Assets/frontend/css/style.css'
import Slider from "../Includes/Slider";
import Expert from "../Includes/Expert";
import Services from "../Includes/Services";
import Projects from "../Includes/Projects";
import About from "../Includes/About";
import CountryLink from "../Includes/CountryLink";
import {Helmet} from "react-helmet";

const Home =()=>{
    return(
        <div>
            <Helmet>
                <title>Welcome | Mega Hot Mop Shower Pan &#8211; Hot Mop</title>
            </Helmet>
            <Slider/>
            <Expert/>
            <Services/>
            <Projects/>
            <About/>
            <CountryLink/>
        </div>
    )
}
export default Home
